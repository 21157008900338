import React from 'react'
import Plot from '$components/PlotlyWrapper';

export default () => {
  return (
    <div
      className="container my-5 d-flex justify-content-center"
      style={{ height: '400px' }}
    >
      <Plot
        data={[
          {
            type: 'scattergeo',
            locationmode: 'europe',
            lon: [
              2.240206,
              12.3345899,
              10.00589,
              -1.075614,
              4.4844157,
              14.2138702,
              -1.57796651239553,
              16.2686238,
              -1.780685,
              4.8060329,
              12.5326186,
              12.5051413,
              18.144798,
              25.1166696,
              -5.6960921,
              12.0153085,
              5.1609815,
              4.7628065,
              6.1763552,
              16.2836918,
              8.404437,
              -0.5291847,
              10.8876149,
              5.21624403043807,
              2.30551,
              3.24971372626814,
              10.6081382,
              1.2080985,
              12.5000112,
              5.16121663942668,
              5.75567382,
              4.4803453,
              17.1100523,
              11.5879359,
              7.6632641,
              5.8385196119892,
              11.9101019,
              -2.7695752,
              10.3379921,
              7.011429,
              4.3873058,
              2.6500745,
              11.3508972,
              14.286198,
              16.7766333,
              24.2519843685077,
              -5.9953171,
              2.398932,
              -0.4968888,
              2.5589558,
              11.6399609,
            ],
            lat: [
              48.8356649,
              45.4371908,
              48.3954965,
              50.8036831,
              50.6860037,
              42.4645837,
              43.43802545,
              48.1507519,
              52.290713,
              43.9493143,
              55.678016,
              55.7718649,
              49.59889,
              60.4000005,
              54.593332,
              57.6564918,
              51.1054908,
              44.7128682,
              49.1196964,
              48.1070931,
              49.014068,
              45.0121558,
              49.8926723,
              52.24253135,
              48.9026,
              51.1511469,
              49.5816766,
              48.3910238,
              55.8832986,
              52.03061555,
              51.95078405,
              51.0281381,
              48.1352041,
              50.9281717,
              48.4227385,
              51.84260195,
              57.6573915,
              48.5348817,
              47.7388032,
              51.457087,
              45.4401467,
              39.5695818,
              48.0001038,
              48.3059078,
              45.4856539,
              45.86896535,
              37.3886303,
              47.0805693,
              44.7846424,
              48.9802035,
              52.1315889,
            ],
            text: [
              'Boulogne-Billancourt<br>750000.0',
              'Venetia<br>750000.0',
              'Neu-Ulm<br>750000.0',
              'Portsmouth<br>771856.8117',
              'Lasne<br>800000.0',
              'Pescara<br>800000.0',
              'Bidart<br>800000.0',
              'Mauer<br>800000.0',
              'Henley-in-Arden<br>870388.4128',
              'Avignon<br>890000.0',
              'Frederiksberg<br>891590.0',
              'Lyngby<br>898360.0',
              'Kop\u0159ivnice<br>899530.0',
              'Kerava<br>900000.0',
              'Newtownards<br>934200.8754',
              'M\u00f6lndal<br>945270.0',
              'Hamburg<br>950000.0',
              'Baix<br>1000000.0',
              'Metz<br>1000000.0',
              'Brunn am Gebirge<br>1000000.0',
              'Karlsruhe<br>1000000.0',
              'Ambes<br>1000000.0',
              'Bamberg<br>1000000.0',
              'Laren<br>1000000.0',
              'Clichy<br>1000000.0',
              'Oostkamp<br>1000000.0',
              'Neustadt an der Aisch<br>1000000.0',
              'Villebon<br>1000000.0',
              'H\u00f8rsholm<br>1000000.0',
              'Houten<br>1000000.0',
              'Heteren<br>1000000.0',
              'Mechelen<br>1000000.0',
              'Slov\u00e1kov<br>1000000.0',
              'Jena<br>1000000.0',
              'Erstein<br>1000000.0',
              'Nijmegen<br>1000000.0',
              'V\u00e4stra Fr\u00f6lunda<br>1000000.0',
              'Plerin<br>1000000.0',
              'Kempten<br>1000000.0',
              'Essen<br>1000000.0',
              'Saint-\u00c9tienne<br>1000000.0',
              'Palma de Mallorca<br>1000000.0',
              'Starnberg<br>1000000.0',
              'Linz<br>1000000.0',
              'Kutina<br>1000000.0',
              'Sibiu<br>1000000.0',
              'Seville<br>1000000.0',
              'Bourges<br>1000000.0',
              'Latresne<br>1000000.0',
              'Tremblay-en-France<br>1000000.0',
              'Magdeburg<br>1000000.0',
            ],
            name: '0.5M-1M',
            opacity: 0.9,
            marker: {
              size: [
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
              ],
              color: [
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
                '#D3D3D3',
              ],
              sizemode: 'area',
              line: { width: 0.2, color: 'rgb(40,40,40)' },
            },
          },
          {
            type: 'scattergeo',
            locationmode: 'europe',
            lon: [
              13.4424603547596,
              9.12665447287107,
              4.098112,
              18.7160209,
              22.9418905,
              10.8690315851516,
              2.9203275,
              -3.3660907,
              11.8604618783394,
              -2.24436180578358,
              18.3049291,
              -3.9467751,
              -0.3622403,
              5.745715,
              5.4692947,
              5.87623450776413,
              4.4392506,
              7.6824892,
              -7.9092808,
              2.2956951,
              5.7017388,
              -0.0999051,
              1.4749101,
              4.67532055295702,
              2.1374969,
              -1.5715573,
              -2.0913745,
              11.1632513994763,
              -0.1729127,
              8.5716274,
              -5.6640182,
              -1.47186777820379,
              4.8869339,
              7.3579641,
              3.4488518,
              -1.3236425,
              9.5842346,
              25.1272630822577,
              -0.8801349,
              9.669839,
              14.6357061,
              11.2555757,
              6.759562,
              3.3968487,
              11.8734455,
              2.0832668,
              23.3221789,
              14.5329436,
              2.7860159,
              -0.7589607,
              18.2248628533484,
              4.5102844821607,
              19.8185974156006,
              -7.1119081,
              20.9719237558469,
              15.64477,
              14.2487826,
              -5.9953171,
              22.5131199,
              -0.4901846,
              -0.3759513,
              20.2630745,
              7.4514512,
              8.4611036,
              12.0974869,
              4.9441635,
              9.7385532,
              14.2044214,
              4.81594421954726,
              5.4952155,
              5.3428796,
              5.3965879493517,
              13.3815238,
              10.4018624,
              12.9252977,
              12.7040684,
              3.1506621,
              -0.5653624,
              -1.4702278,
              -0.0464975,
              -0.607692,
              -0.767216744425342,
              6.135465100226,
              -1.166667,
              -2.160243,
              -21.8248138651564,
              -2.1869766,
              17.9442849,
              6.488224,
              3.2659948,
              -1.9838889,
              7.0085848,
              1.0466542299927,
              22.1460134,
              4.0392147,
              12.9810458,
              10.6622880355347,
              6.9567612,
              6.2675845,
              -9.0490632,
              -6.60052495,
              0.6176324,
              -5.9300992,
              6.09772035967769,
              10.9924122,
              15.977048,
              -16.6214471211441,
              10.1925475,
              3.1741734,
              4.9903187,
              3.0162523,
              -1.5518632,
              2.3872525,
              2.2735978,
              5.2482643,
              7.7116218,
              26.9450844,
              -4.1425658,
              -2.9349459,
              -1.685398,
              15.5866422,
              5.29191881791502,
              -2.3596963,
              -2.1159032,
              1.8554319,
              5.5654672,
              7.2196635,
              12.4377222,
              5.5001456,
              11.3430347,
              -1.2779715,
              18.1718482,
              11.2921549,
              -0.6469599,
              3.5794275,
              7.61330165026119,
              4.3873058,
              21.883333,
              -1.03187295933992,
              10.6179445,
              5.41215471360364,
              20.2630745,
              -2.0711559,
              14.5068921,
              10.3951929,
              -4.1268822,
              -2.9735213,
              10.6304958,
              12.3810549,
              -6.0353142,
              7.1441932,
              7.2683912,
              -4.88562,
              6.55824533479853,
              5.8008839,
              9.6874573,
              -0.798151498727102,
              6.87059566409799,
              25.6561814,
              2.2561602,
              2.87227558236094,
              4.64355969655963,
            ],
            lat: [
              57.81721795,
              59.6918539,
              51.0312293,
              63.2888613,
              39.3624382,
              59.18454105,
              51.2303177,
              47.7477336,
              43.4640246,
              53.2796187,
              59.2792775,
              51.6211442,
              51.6441737,
              45.2090131,
              43.4547814,
              52.0056642,
              46.4819108,
              45.0677551,
              38.5707742,
              49.8941708,
              44.9656674,
              51.5384287,
              43.5460533,
              52.3005585,
              41.4910324,
              54.624581,
              57.1452452,
              61.00264955,
              51.6487843,
              50.1426423,
              40.9651572,
              43.48437155,
              45.7733105,
              48.0777517,
              51.0859007,
              51.4020055,
              57.2523474,
              60.2246684,
              47.0617293,
              45.6945115,
              63.1793655,
              43.7698712,
              51.434999,
              43.5757523,
              45.4077172,
              41.4728392,
              42.6978634,
              63.1924552,
              48.8445565,
              52.0429797,
              59.29973065,
              51.1599742,
              41.3221362,
              52.2609997,
              64.742143,
              46.555628,
              40.8359336,
              37.3886303,
              52.9154988,
              38.353738,
              39.4699014,
              63.8256568,
              46.9482713,
              47.1823761,
              49.0195333,
              45.6965347,
              52.3744779,
              48.2576453,
              52.45030615,
              43.4867359,
              50.2240814,
              52.1637739,
              54.095791,
              43.7159395,
              50.8322608,
              56.0441984,
              50.6271588,
              51.2352672,
              53.3806626,
              43.0940904,
              51.676685,
              51.3291643,
              45.88975045,
              51.083333,
              53.407901,
              64.08744185,
              51.4303211,
              59.403311,
              44.8694993,
              50.8276199,
              43.3224219,
              43.6413793,
              52.24115585,
              65.5831352,
              50.9383226,
              50.9854597,
              59.464916,
              47.2243743,
              46.4212913,
              53.2744122,
              53.38443,
              44.2015624,
              54.5969869,
              52.51456525,
              45.4384958,
              45.813177,
              28.2935785,
              46.2847547,
              50.6915893,
              51.1610826,
              50.6092082,
              47.2996846,
              48.8122302,
              48.7282317,
              43.4593696,
              48.5298611,
              60.4674228,
              50.3712659,
              43.2629489,
              48.1524129,
              56.1621073,
              51.7099516,
              51.3813864,
              51.4587621,
              48.9591392,
              45.3259232,
              51.4818111,
              55.7248173,
              50.9654864,
              44.4936714,
              51.6714842,
              40.3570373,
              48.1140289,
              44.8422361,
              47.8483265,
              51.9501317,
              45.4401467,
              61.433333,
              51.4534889,
              59.8973047,
              51.036563,
              63.8256568,
              51.8995685,
              46.049865,
              63.4305658,
              53.2277187,
              56.4593653,
              44.6978446,
              51.3391827,
              54.5129355,
              46.8239731,
              43.7009358,
              36.508976,
              53.2217925,
              43.1177238,
              45.362828,
              51.21023055,
              52.22336325,
              60.9838761,
              48.8953328,
              51.19996685,
              52.38370575,
            ],
            text: [
              'Ulricehamn<br>1024600.0',
              'Notodden<br>1050000.0',
              'Dendermonde<br>1052000.0',
              '\u00d6rnsk\u00f6ldsvik<br>1053600.0',
              'Volos<br>1069224.0',
              'Fredrikstad<br>1079436.0',
              'Oostende<br>1084276.88',
              'Lorient<br>1100000.0',
              'Arezzo<br>1100000.0',
              'Nether Alderley<br>1142010.0',
              'Saltsj\u00f6baden<br>1143670.0',
              'Swansea<br>1191680.0',
              'Bushey<br>1197136.8',
              'La Tronche<br>1200000.0',
              'Gardanne<br>1200000.0',
              'Arnhem<br>1200000.0',
              'Saint-Bonnet-de-Joux<br>1200000.0',
              'Turin<br>1200000.0',
              '\u00c9vora<br>1250000.0',
              'Amiens<br>1250000.0',
              'Monteynard<br>1250000.0',
              'Islington<br>1287580.0',
              'Ramonville-Saint-Agne<br>1300000.0',
              'Hoofddorp<br>1300000.0',
              'Cerdanyola del Vall\u00e8s<br>1300000.0',
              'Newton Aycliffe<br>1304847.5',
              'Aberdeen<br>1310848.0',
              'Hamar<br>1331160.0',
              'Barnet<br>1334565.0',
              'Eschborn<br>1350000.0',
              'Salamanca<br>1400000.0',
              'Bayonne<br>1400000.0',
              'Villeurbanne<br>1400000.0',
              'Colmar<br>1400000.0',
              'Aalter<br>1466609.0',
              'Newbury<br>1469787.5',
              'Blokhus<br>1478950.0',
              'Uusimaa<br>1500000.0',
              'Cholet<br>1500000.0',
              'Bergamo<br>1500000.0',
              '\u00d6stersund<br>1500000.0',
              'Florence<br>1500000.0',
              'Duisburg<br>1500000.0',
              'P\u00e9ret<br>1500000.0',
              'Padova<br>1500000.0',
              'Sant Cugat Del Vall\u00e8s<br>1500000.0',
              'Sofia<br>1526400.0',
              'Fr\u00f6s\u00f6n<br>1567950.0',
              'Serris<br>1600000.0',
              'Milton Keynes<br>1668000.0',
              'Nacka<br>1742720.0',
              'Boechout<br>1770000.0',
              'Kosovo<br>1776380.0',
              'Waterford<br>1800000.0',
              'Skellefte\u00e5<br>1836000.0',
              'Maribor<br>1837520.0',
              'Naples<br>1865000.0',
              'Sevilla<br>1882400.0',
              'Wysokie Mazowieckie<br>1886556.0',
              'Alicante<br>1887532.5',
              'Valencia<br>1907410.0',
              'Umea<br>1916002.0',
              'Bern<br>1921390.0',
              'Cham<br>1989284.0',
              'Regensburg<br>2000000.0',
              'Saint-Priest<br>2000000.0',
              'Hanover<br>2000000.0',
              'Pasching<br>2000000.0',
              'Zaandam<br>2000000.0',
              'Meyreuil<br>2000000.0',
              'Marche-en-Famenne<br>2000000.0',
              'Amersfoort<br>2000000.0',
              'Greifswald<br>2000000.0',
              'Pisa<br>2017207.2',
              'Chemnitz<br>2020000.0',
              'Helsingborg<br>2054800.0',
              "Villeneuve-d'Ascq<br>2100000.0",
              'Guildford<br>2162949.6112',
              'Sheffield<br>2190722.0',
              'Lourdes<br>2200000.0',
              'Amersham<br>2268625.0',
              'Camberley, Surrey<br>2290000.0',
              'Annecy<br>2325920.0',
              'Hampshire<br>2330380.0',
              'Stockport<br>2332640.0',
              'K\u00f3pavogur<br>2335736.0',
              'Corsham<br>2362700.0',
              'Kista<br>2486505.0',
              'Saint-Antoine<br>2500000.0',
              'Kortrijk<br>2500000.0',
              'San Sebastian<br>2500000.0',
              'Valbonne<br>2500000.0',
              'Suffolk<br>2519000.0',
              'Lule\u00e5<br>2533750.0',
              'Aalst<br>2550000.0',
              'Mittweida<br>2612720.0',
              'Moss<br>2674770.0',
              'Le Noirmont<br>2700000.0',
              'Gland<br>2799510.0',
              'Galway<br>2877580.0',
              'Maynooth Kildare<br>2886617.5',
              'Agen<br>2950000.0',
              'Belfast<br>2950684.0',
              'Zwolle<br>3000000.0',
              'Verona<br>3000000.0',
              'Zagreb<br>3000000.0',
              'Tenerife<br>3000000.0',
              'Campiano<br>3000000.0',
              'Roubaix<br>3000000.0',
              'Geel<br>3000000.0',
              'Loos<br>3000000.0',
              'La Chapelle-sur-erdre<br>3000000.0',
              'Ivry-sur-Seine<br>3000000.0',
              'Massy<br>3000000.0',
              'Vitrolles<br>3000000.0',
              'Illkirch<br>3000000.0',
              'Kotka<br>3200000.0',
              'Plymouth<br>3299608.0',
              'Bilbao<br>3300000.0',
              'Saint Gr\u00e9goire<br>3300000.0',
              'Karlskrona<br>3392814.0',
              'Den Bosch<br>3400000.0',
              'Bath<br>3435000.0',
              'Chippenham<br>3469440.0',
              'Aubergenville<br>3500000.0',
              'Moirans<br>3500000.0',
              'Bochum<br>3500000.0',
              'Herlev<br>3500000.0',
              'Genk<br>3535720.0',
              'Bologna<br>3900000.0',
              'Abingdon<br>3990456.0',
              'Lecce<br>4000000.0',
              'Gilching<br>4000000.0',
              'M\u00e9rignac<br>4000000.0',
              'Mon\u00e9teau<br>4000000.0',
              'M\u00fcnster<br>4000000.0',
              'Saint Etienne<br>4000000.0',
              'Ulvila<br>4100000.0',
              'Berkshire<br>4174493.0',
              'Fornebu<br>4189600.0',
              'Houthalen<br>4200000.0',
              'Ume\u00e5<br>4229962.0',
              'Cheltenham<br>4318125.0',
              'Ljubljana<br>4400000.0',
              'Trondheim<br>4423700.0',
              'Bangor<br>4448000.0',
              'Dundee<br>4491800.0',
              'Reggio Emilia<br>4500000.0',
              'Leipzig<br>4500000.0',
              'Lisburn<br>4506530.0',
              'Granges-Paccot<br>4578350.0',
              'Nice<br>4600000.0',
              'Marbella<br>4637000.0',
              'Groningen<br>4700000.0',
              'Sanary-sur-Mer<br>4700000.0',
              'Crema<br>4866070.0',
              'Farnham<br>4921638.4',
              'Enschede<br>4950000.0',
              'Lahti<br>5000000.0',
              'Courbevoie<br>5000000.0',
              'Ostend<br>5000000.0',
              'Haarlem<br>5000000.0',
            ],
            name: '1M-5M',
            opacity: 0.9,
            marker: {
              size: [
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
              ],
              color: [
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
                '#3CBEC5',
              ],
              sizemode: 'area',
              line: { width: 0.2, color: 'rgb(40,40,40)' },
            },
          },
          {
            type: 'scattergeo',
            locationmode: 'europe',
            lon: [
              -1.5426885,
              4.47566054644093,
              -8.8825488,
              17.0326689,
              0.6889268,
              7.0966445,
              5.325551,
              -2.1255158,
              8.8071646,
              25.745951,
              12.4829321,
              -2.9738085,
              -1.8948516,
              -4.182994,
              8.467236,
              9.7423955,
              14.1627579,
              6.6409158,
              7.7507127,
              8.2710237,
              17.1597440625,
              -0.5564726,
              -0.7312608,
              11.6715263,
              13.0591397,
              11.077298,
              0.5989157,
              -9.260741,
              -1.7494778,
              8.85965085923002,
              -1.0006184,
              -3.787263,
              12.5088728,
              12.3747797,
              -0.6680194,
              -2.1474345,
              -2.57763215785817,
              24.1665986020787,
              -8.4705806,
              23.7279843,
              6.64699446358719,
              7.8494005,
              13.3534422,
              -3.1791934,
              4.3113461,
              -4.2435817,
              13.502948,
              7.0477082,
              13.4606445,
              3.124765,
              -0.2003247,
              -0.6988488,
              1.0939658,
              5.6062495,
              18.297982,
              14.3079604,
              2.6500745,
              19.0404707,
              8.694724,
              5.0028581383324,
              25.9276205,
              4.32250293752741,
              8.9338624,
              4.4796542,
            ],
            lat: [
              50.7583753,
              46.5774595,
              52.7111567,
              51.1089776,
              47.3900474,
              43.6280334,
              60.3943532,
              53.2606635,
              53.0758196,
              62.2393002,
              41.8933203,
              43.304913,
              52.4776875,
              55.7653249,
              49.489591,
              47.4135969,
              57.7814709,
              46.7785711,
              48.584614,
              49.9999952,
              48.1359085,
              51.3201891,
              51.234842,
              48.2242434,
              52.4009309,
              49.453872,
              49.0902278,
              39.0930856,
              53.7941567,
              47.2298277,
              37.6267868,
              51.5954335,
              55.736468,
              45.6279075,
              51.4101286,
              52.4577615,
              50.9725836,
              56.9716562,
              51.8979282,
              37.9841493,
              46.78101155,
              47.9960901,
              38.11127,
              51.4816546,
              52.0799838,
              55.856656,
              59.3799132,
              43.619776,
              48.5740136,
              50.9444948,
              50.9346502,
              38.2653181,
              49.4404591,
              43.1758591,
              57.6379361,
              46.6228162,
              39.5695818,
              47.4983815,
              49.4093582,
              52.1645542,
              60.6601217,
              52.03739335,
              44.40726,
              50.7315053,
            ],
            text: [
              'Lymington<br>5144945.4',
              'Rousset<br>5200000.0',
              'Shannon<br>5200000.0',
              'Wroclaw<br>5254212.0',
              'Tours<br>5300000.0',
              'Biot<br>5300000.0',
              'Bergen<br>5308440.0',
              'Macclesfield<br>5346120.0',
              'Bremen<br>5363820.0',
              'Jyv\u00e4skyl\u00e4<br>5400000.0',
              'Rome<br>5415080.5',
              'Erandio<br>5750810.0',
              'Birmingham<br>5855400.0',
              'East Kilbride<br>5874838.0',
              'Mannheim<br>6051603.6',
              'Dornbirn<br>6100000.0',
              'J\u00f6nk\u00f6ping<br>6180000.0',
              'Yverdon-les Bains<br>6196087.5',
              'Strasbourg<br>6200000.0',
              'Mainz<br>6265000.0',
              'Bratislava<br>6268625.0',
              'Woking<br>6289596.0',
              'Tongham<br>6378153.0',
              'Ismaning<br>6400000.0',
              'Potsdam<br>6500000.0',
              'Nuremberg<br>7000000.0',
              'Bernay<br>7000000.0',
              'Torres Vedras<br>7000000.0',
              'Bradford<br>7039998.0',
              'Rapperswil-Jona<br>7043325.0',
              'Cartagena<br>7300000.0',
              'Port Talbot<br>7375225.0',
              'S\u00f8borg<br>7399700.52855',
              'Roncade<br>7400000.0',
              'Ascot<br>7526620.0',
              'Stourbridge<br>7533832.0',
              'Trent<br>7694765.0',
              'Riga<br>7700000.0',
              'Cork<br>7700000.0',
              'Athens<br>7700000.0',
              'Yverdon-les-bains, Vaud<br>7821530.0',
              'Freiburg<br>8000000.0',
              'Palermo<br>8000000.0',
              'Cardiff<br>8020877.90248',
              'The Hague<br>8300000.0',
              'Glasgow<br>8430482.16',
              'Karlstad<br>8481630.0',
              'Sophia Antipolis<br>8600000.0',
              'Passau<br>8624820.0',
              'Roeselare<br>8730000.0',
              'Albourne<br>8764275.0',
              'Elche<br>8823858.4',
              'Rouen<br>8900000.0',
              'La Ciotat<br>9000000.0',
              'Visby<br>9020804.0',
              'Klagenfurt<br>9145473.0',
              'Palma De Mallorca<br>9500000.0',
              'Budapest<br>9876815.5',
              'Heidelberg<br>9950000.0',
              'Breukelen<br>10000000.0',
              'Vuorela<br>10000000.0',
              'Rijswijk<br>10000000.0',
              'Genoa<br>10000000.0',
              'La Hulpe<br>10000000.0',
            ],
            name: '5M-10M',
            opacity: 0.9,
            marker: {
              size: [
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                11,
                11,
                11,
                11,
                11,
              ],
              color: [
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
                '#19B278',
              ],
              sizemode: 'area',
              line: { width: 0.2, color: 'rgb(40,40,40)' },
            },
          },
          {
            type: 'scattergeo',
            locationmode: 'europe',
            lon: [
              5.09293340872922,
              4.4436244,
              -3.5266611,
              16.6113382,
              2.005589,
              10.3852104,
              13.7381437,
              33.6378531,
              0.7296722,
              27.594561484489,
              16.5463679,
              6.083862,
              25.0364526,
              2.2468567,
              2.4867977,
              -1.5437941,
              9.9193939,
              -0.1374061,
              7.5878261,
              -2.9805392,
              -1.6142059,
              4.7011675,
              -2.56803707272251,
              5.4474738,
              7.4652789,
              5.2877621,
              -0.4932941,
              4.362724538544,
              -0.1801094,
              0.221268472516625,
              10.4448546719652,
              2.1250543,
              14.8094385,
              -0.5800364,
              4.6127019,
              8.682093,
              14.4212535,
              7.2576644,
              28.1875302,
              -4.3965573,
              22.2670522,
              15.4385716,
              -3.4589104,
              8.9520281,
              5.45012252185714,
              17.64112,
              9.4762981,
              2.1874811,
              2.31606,
              -2.6751258,
              5.3699525,
              -0.0672286,
              -1.1492773,
              4.48862326925829,
              5.1155267,
              12.109015599915,
              10.2134046,
              -0.9696512,
              -1.5541362,
              2.1266886,
              5.7274722,
              8.76486977817756,
              15.6245252,
              -8.6107884,
              -1.0815361,
              26.7237528,
              -2.2040788,
              8.5173652,
              5.12768031549829,
              -1.6142059,
              6.6055827,
              5.3378043,
              3.8767337,
              -0.3957425,
              -21.9422367,
              3.0706414,
              -6.1788342,
              26.1027202,
              20.4568089,
              -1.6800198,
              4.3997081,
              -1.0861976,
              13.6348744,
              23.7603118,
              -2.2441009,
              19.9371393,
              -1.2909164,
              1.4442469,
              25.4716809,
              6.959974,
              24.7453688,
              -1.404189,
              1.9090697,
              -2.5972985,
              -8.6301239,
              21.0067265,
              6.1296751,
              -9.1365061,
              9.1800132,
              10.4019263,
              4.8320114,
              13.1929449,
              3.7250121,
              -1.5758539,
              9.1904984,
              13.0001566,
              4.351697,
              24.6603626,
              11.9670171,
              14.5020708,
              1.5299721,
              10.7391112,
              -1.2578499,
              6.1466014,
              6.6327025,
              16.3725042,
              24.9425683,
              -3.1914972,
              0.124862,
              6.7763137,
              -3.7035825,
              5.089667,
              11.5753822,
              12.5700724,
              10.000654,
              -6.2602732,
            ],
            lat: [
              52.0298704,
              50.4120332,
              50.7256471,
              49.1922443,
              43.4582102,
              55.3997225,
              51.0493286,
              34.9172012,
              52.0386628,
              62.8241424,
              59.6110992,
              50.776351,
              60.3091871,
              48.7170283,
              48.7464991,
              53.7974185,
              57.0482206,
              50.8220399,
              47.5581077,
              53.4054719,
              54.9770924,
              50.879202,
              53.40180345,
              43.5298424,
              51.5142273,
              51.8799104,
              47.4683081,
              51.9994572,
              51.1103444,
              52.0743031,
              59.40796625,
              48.6718036,
              56.8787183,
              44.841225,
              50.6365628,
              50.110653,
              50.0874654,
              46.2103405,
              61.0582424,
              48.4075514,
              60.4517531,
              47.0702563,
              48.7322804,
              46.0050102,
              51.44855695,
              59.8594126,
              51.3090209,
              48.6991805,
              48.8144,
              51.6428466,
              43.2961743,
              51.5434023,
              52.9534161,
              51.92779805,
              51.1840421,
              54.14736345,
              56.1496278,
              51.456659,
              47.2186371,
              48.8035403,
              45.182151,
              51.71895955,
              58.4098135,
              41.1494512,
              53.9590555,
              58.3727059,
              51.3191947,
              47.1679898,
              52.08095165,
              54.9770924,
              46.534566,
              50.9303735,
              43.6112422,
              51.6553875,
              64.145981,
              50.6305089,
              53.3018639,
              44.4361414,
              44.8178787,
              48.1113387,
              51.2211097,
              51.262826,
              52.3214429,
              61.4980214,
              53.4791301,
              50.0606742,
              51.5999743,
              43.6044622,
              65.0118734,
              50.938361,
              59.4372155,
              50.9025349,
              48.9958047,
              51.4538022,
              52.661252,
              52.2319237,
              49.8158683,
              38.7077926,
              48.7784485,
              47.7666821,
              45.7578137,
              55.7029296,
              51.0538286,
              54.7764152,
              45.4667971,
              55.6052931,
              50.8465573,
              60.2241869,
              57.7072326,
              35.9127609,
              43.5292828,
              59.9132694,
              51.7520131,
              46.2017559,
              46.5218269,
              48.2083537,
              60.1674086,
              55.9495628,
              52.2033051,
              51.2254018,
              40.4167047,
              51.0681582,
              48.1371079,
              55.6867243,
              53.550341,
              53.3497645,
            ],
            text: [
              'Nieuwegein<br>10099210.0',
              'Charleroi<br>10249986.11',
              'Exeter<br>10356432.5',
              'Brno<br>10489618.5226',
              'Revel<br>10500000.0',
              'Odense<br>10570160.0',
              'Dresden<br>11000000.0',
              'Larnaca<br>11128800.0',
              'Sudbury<br>11448840.0',
              'Kuopio<br>11500000.0',
              'V\u00e4ster\u00e5s<br>11585680.0',
              'Aachen<br>11750000.0',
              'Vantaa<br>11900000.0',
              'Palaiseau<br>12000000.0',
              'Limeil-Br\u00e9vannes<br>12000000.0',
              'Leeds<br>12399577.0',
              'Aalborg<br>12633450.0',
              'Brighton<br>13104198.0',
              'Basel<br>13308770.0',
              'Liverpool<br>13330333.4',
              'Newcastle<br>13730486.0',
              'Leuven<br>14086650.0',
              'Warrington<br>14109960.0',
              'Aix-en-provence<br>14300000.0',
              'Dortmund<br>14700160.0',
              'Geldermalsen<br>15000000.0',
              "Saint-Barth\u00e9lemy-d'Anjou<br>15000000.0",
              'Delft<br>15100000.0',
              'Crawley<br>15226440.0',
              'Great Chesterford<br>16537680.0',
              'Horten<br>17288000.0',
              'Gometz-la-Ville<br>17820400.0',
              'V\u00e4xj\u00f6<br>17995195.5',
              'Bordeaux<br>18310000.0',
              'Mont-Saint-Guibert<br>18719224.5',
              'Frankfurt<br>18750000.0',
              'Prague<br>18851611.0',
              'Ardon<br>19640460.0',
              'Lappeenranta<br>20000000.0',
              'Le Relecq-Kerhuon<br>20000000.0',
              'Turku<br>20736870.0',
              'Graz<br>21000000.0',
              'Lannion<br>21750000.0',
              'Lugano<br>22118500.0',
              'Eindhoven<br>22307900.0',
              'Uppsala<br>22617620.0',
              'Kassel<br>22929250.0',
              'Orsay<br>23291840.0',
              'Montrouge<br>23500000.0',
              'Chepstow<br>23687800.0',
              'Marseille<br>23850000.0',
              'Dalston<br>23887980.0',
              'Nottingham<br>23929075.5',
              'Rotterdam<br>24429100.0',
              'Mol<br>24964520.0',
              'Rostock<br>25000000.0',
              'Aarhus<br>25219964.4',
              'Reading<br>25789222.0',
              'Nantes<br>26086200.0',
              'Versailles<br>28000000.0',
              'Grenoble<br>29200000.0',
              'Paderborn<br>30000000.0',
              'Link\u00f6ping<br>30626918.0',
              'Porto<br>31924920.0',
              'York<br>31960560.0',
              'Tartu<br>32000000.0',
              'Trowbridge<br>32942000.0',
              'Zug<br>32981113.0',
              'Utrecht<br>33820400.0',
              'Newcastle Upon Tyne<br>34834038.0',
              'Prilly<br>35505200.0',
              'Hasselt<br>35589086.0',
              'Montpellier<br>36700000.0',
              'Watford<br>36738800.0',
              'Reykjavik<br>37934104.2',
              'Lille<br>38599620.0',
              'Blackrock<br>40000000.0',
              'Bucharest<br>40157072.0',
              'Belgrade<br>40495958.8',
              'Rennes<br>40730000.0',
              'Antwerp<br>42042028.0',
              'Basingstoke<br>44024420.0',
              'Wildau<br>44217110.0',
              'Tampere<br>48870000.0',
              'Manchester<br>48939860.8',
              'Cracow<br>49380501.0',
              'Harwell<br>50506862.0',
              'Toulouse<br>51200000.0',
              'Oulu<br>54271350.0',
              'Cologne<br>55222310.0',
              'Tallinn<br>57427252.5',
              'Southampton<br>64499507.0',
              'Les Mureaux<br>66000000.0',
              'Bristol<br>68101908.5',
              'Limerick<br>70033580.0',
              'Warsaw<br>70205688.0',
              'Luxembourg<br>72601850.0',
              'Lisbon<br>73926716.0',
              'Stuttgart<br>74653350.0',
              'Wildpoldsried<br>87133250.0',
              'Lyon<br>95679520.0',
              'Lund<br>96934751.7698',
              'Ghent<br>112697558.0',
              'Durham<br>129992954.538',
              'Milan<br>134418576.0',
              'Malm\u00f6<br>134728763.282',
              'Brussels<br>137204954.63',
              'Espoo<br>145167219.0',
              'Gothenburg<br>149261712.0',
              'Sliema<br>152975520.0',
              'Lab\u00e8ge<br>153250000.0',
              'Oslo<br>167818457.6',
              'Oxford<br>201354064.472',
              'Geneva<br>201944871.5',
              'Lausanne<br>219915291.7',
              'Vienna<br>235192106.6',
              'Helsinki<br>236621096.94',
              'Edinburgh<br>244452214.64',
              'Cambridge<br>286756391.525',
              'D\u00fcsseldorf<br>287519620.0',
              'Madrid<br>372853116.0',
              'Tessenderlo<br>400000000.0',
              'Munich<br>435799205.0',
              'Copenhagen<br>439913132.4',
              'Hamburg<br>454943294.0',
              'Dublin<br>478049368.0',
            ],
            name: '10-500M',
            opacity: 0.9,
            marker: {
              size: [
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                15,
                15,
                15,
                15,
                15,
                16,
                16,
                16,
                17,
                17,
                17,
                17,
                17,
                18,
                19,
                19,
                21,
                22,
                23,
                23,
                23,
                24,
                24,
                25,
                25,
                26,
                30,
                30,
                31,
                33,
                33,
                34,
                38,
                38,
                47,
                50,
                53,
                53,
                55,
                57,
              ],
              color: [
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
                '#F7A852',
              ],
              sizemode: 'area',
              line: { width: 0.2, color: 'rgb(40,40,40)' },
            },
          },
          {
            type: 'scattergeo',
            locationmode: 'europe',
            lon: [
              8.5423216,
              4.89797550561798,
              2.1774322,
              12.3612313,
              18.0710935,
              13.3888599,
              2.3514992,
              -0.1276474,
            ],
            lat: [
              47.3723957,
              52.3745403,
              41.3828939,
              55.7303589,
              59.3251172,
              52.5170365,
              48.8566101,
              51.5073219,
            ],
            text: [
              'Zurich<br>553893615.0',
              'Amsterdam<br>558016736.0',
              'Barcelona<br>602632619.164',
              'Ballerup<br>2112705000.0',
              'Stockholm<br>2330926931.35',
              'Berlin<br>2644760201.4',
              'Paris<br>2952620156.5',
              'London<br>4879974633.98',
            ],
            name: '>500M',
            opacity: 0.9,
            marker: {
              size: [65, 65, 70, 221, 243, 274, 305, 497],
              color: [
                '#EF758A',
                '#EF758A',
                '#EF758A',
                '#EF758A',
                '#EF758A',
                '#EF758A',
                '#EF758A',
                '#EF758A',
              ],
              sizemode: 'area',
              line: { width: 0.2, color: 'rgb(40,40,40)' },
            },
          },
        ]}
        layout={{
          width: '100%',
          height: '100%',
          title: 'Sum Raised per City',
          showlegend: true,
          geo: {
            scope: 'europe',
            showframe: true,
            showland: true,
            landcolor: 'rgb(217, 217, 217)',
            countrywidth: 1,
            countrycolor: 'rgb(255, 255, 255)',
          },
        }}
      />
    </div>
  )
}
