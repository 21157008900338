import React, { Component } from 'react'

import './style.scss'

export default class CheckedList extends Component {
  render() {
    const { children } = this.props
    return (
      <div className="c_checked-list">
        {children}
        <span className="spacer">&nbsp;</span>
      </div>
    )
  }
}
