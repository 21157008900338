import React, { Component } from 'react'
import { withPrefix } from 'gatsby'
import { Typeahead } from 'react-bootstrap-typeahead' // ES2015

import axios from 'axios'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import './style.scss'

export default class HeadlineTableSearch extends Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.getWordHeadlines = this.getWordHeadlines.bind(this)
  }

  state = {
    hasLoaded: false,
    word: '',
  }
  async componentDidMount() {
    // load the necessary json files
    // all the data files reside in a static directory to prevent them from getting picked up by webpack and bloating the bundle size
    // axios.get(withPrefix('/data/headlines.json')),
    //   axios.get(withPrefix('/data/vocabularyHead.json')),

    try {
      const { data: headlines } = await axios(
        withPrefix('/data/headlines.json')
      )
      const { data: wordSuggestions } = await axios(
        withPrefix('/data/vocabularyHead.json')
      )

      this.setState({
        hasLoaded: true,
        headlines,
        filteredHeadlines: headlines,
        wordSuggestions,
        word: 'facebook',
      })
    } catch (e) {
      console.log(e)
      this.setState({ hasFailed: true })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // check if there's a new word selected, if so recalculate the similarity
    if (this.state.word != prevState.word) {
      this.setState({
        filteredHeadlines: this.getWordHeadlines(this.state.word),
      })
    }
  }

  getWordHeadlines(word) {
    const { headlines } = this.state
    const reg = new RegExp('\\b' + word + '\\b', 'g')

    const filteredHeadlines = headlines.filter(headline =>
      headline.headlineCl.match(reg)
    )

    return filteredHeadlines
  }

  onSubmit(value) {
    this.setState({ word: value })
  }

  render() {
    const {
      //  word, wordData,
      word,
      headlines,
      wordSuggestions,
      filteredHeadlines,
    } = this.state


    return (
      <section className="">
        {this.state.hasLoaded ? (
          <>
            <div className="row pt-5 pb-3">
              <div className="col-sm-12">
                <div className="text-center">
                  <h3>Type any word here to see the top headlines using it:</h3>
                  <div className="text-left d-flex justify-content-center">
                    <div className="c-typeahead">
                      <Typeahead
                        options={wordSuggestions}
                        onChange={this.onSubmit}
                        value={word}
                        placeholder="Enter your search term"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-grid" id="chrt">
              <div className="col-md-12">
                <div className="text" id="tbl">
                  <div className="text-700" />
                  <table id="records_table" className="table table-striped">
                    <thead>
                      <tr>
                        <th>Headline</th>
                        <th>Blog</th>
                        <th>Tweets</th>
                        <th>FB Shares</th>
                        <th>FB Likes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* limit to a low number of rows if there's no word to keep performance up */}
                      {filteredHeadlines
                        .slice(0, word.length ? filteredHeadlines.length : 10)
                        .map((headline, i) => (
                          <tr key={headline.headlineCl + i}>
                            <td>{headline['headline']}</td>
                            <td>{headline['blog']}</td>
                            <td>{headline['facebook likes']}</td>
                            <td>{headline['facebook shares']}</td>
                            <td>{headline['tweets']}</td>
                          </tr>
                        ))}
                      {/* <td>{headline['facebook comments']}</td> */}
                      {/* blog */}
                      {/* headlineCl */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center py-5">
            {this.state.hasFailed ? <>loading failed</> : <>loading...</>}
          </div>
        )}
      </section>
    )
  }
}
