import React from 'react'
import Plot from '$components/PlotlyWrapper';

export default () => {
  return (
    <div
      className="container my-5 d-flex justify-content-center"
      style={{ height: '400px' }}
    >
      <Plot
        data={[
          {
            type: 'choropleth',
            locations: [
              'ALB',
              'MLT',
              'CYP',
              'BGR',
              'SRB',
              'SVN',
              'LVA',
              'HRV',
              'GRC',
            ],
            z: [1, 1, 1, 1, 2, 2, 3, 3, 3],
            text: [
              'Albania',
              'Malta',
              'Cyprus',
              'Bulgaria',
              'Serbia',
              'Slovenia',
              'Latvia',
              'Croatia',
              'Greece',
            ],
            name: '0-3',
            showscale: false,
            colorscale: [[0, '#ebf8f9'], [1, '#ebf8f9']],
          },
          {
            type: 'scattergeo',
            lon: [0],
            lat: [0],
            marker: { color: '#ebf8f9' },
            name: '0-3',
          },
          {
            type: 'choropleth',
            locations: ['HUN', 'SVK', 'ROU', 'LUX'],
            z: [4, 4, 5, 6],
            text: ['Hungary', 'Slovakia', 'Romania', 'Luxembourg'],
            name: '4-7',
            showscale: false,
            colorscale: [[0, '#d8f2f3'], [1, '#d8f2f3']],
          },
          {
            type: 'scattergeo',
            lon: [0],
            lat: [0],
            marker: { color: '#d8f2f3' },
            name: '4-7',
          },
          {
            type: 'choropleth',
            locations: ['EST', 'CZE', 'ISL', 'PRT', 'POL', 'AUT'],
            z: [9, 9, 10, 14, 20, 29],
            text: [
              'Estonia',
              'Czech Republic',
              'Iceland',
              'Portugal',
              'Poland',
              'Austria',
            ],
            name: '8-30',
            showscale: false,
            colorscale: [[0, '#b1e5e7'], [1, '#b1e5e7']],
          },
          {
            type: 'scattergeo',
            lon: [0],
            lat: [0],
            marker: { color: '#b1e5e7' },
            name: '8-30',
          },
          {
            type: 'choropleth',
            locations: ['NOR', 'DNK', 'ITA', 'IRL', 'CHE', 'FIN', 'NLD', 'BEL'],
            z: [38, 62, 63, 65, 79, 85, 92, 95],
            text: [
              'Norway',
              'Denmark',
              'Italy',
              'Ireland',
              'Switzerland',
              'Finland',
              'Netherlands',
              'Belgium',
            ],
            name: '31-100',
            showscale: false,
            colorscale: [[0, '#8ad8dc'], [1, '#8ad8dc']],
          },
          {
            type: 'scattergeo',
            lon: [0],
            lat: [0],
            marker: { color: '#8ad8dc' },
            name: '31-100',
          },
          {
            type: 'choropleth',
            locations: ['ESP', 'SWE', 'DEU', 'FRA', 'GBR'],
            z: [117, 246, 312, 534, 543],
            text: ['Spain', 'Sweden', 'Germany', 'France', 'United Kingdom'],
            name: '>100',
            showscale: false,
            colorscale: [[0, '#3cbec5'], [1, '#3cbec5']],
          },
          {
            type: 'scattergeo',
            lon: [0],
            lat: [0],
            marker: { color: '#3cbec5' },
            name: '>100',
          },
        ]}
        layout={{
          width: '100%',
          height: '100%',
          title: '# Startups',
          showlegend: true,
          geo: {
            scope: 'europe',
            showframe: true,
            showcoastlines: false,
            countrycolor: '#D3D3D3',
          },
        }}
      />
    </div>
  )
}
