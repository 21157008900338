import React from 'react'

export default function Breakout({ wide, className, children }) {
  return (
    <div
      className={`my-2 my-sm-5 breakout ${className} ${
        wide ? 'breakout--wide' : ''
      }`}
    >
      {children}
    </div>
  )
}
