import React, { Component } from 'react'

import './style.scss'

export default class MassiveQuote extends Component {
  render() {
    const { children } = this.props
    return (
      <div className="c-massive-quote bg-primary font-weight-bold text-white py-3 my-2 py-lg-5 my-md-4 my-lg-5">
        <div className="c-massive-quote__inner">{children}</div>
        <div className="c-massive-quote__source">{this.props.source}</div>
      </div>
    )
  }
}
