import React, { Component } from 'react'

import createPlotlyComponent from 'react-plotly.js/factory'

export default class PlotlyWrapper extends Component {
  state = {}

  impFn() {
    return import('./CustomPlotly')
  }

  componentDidMount = () => {
    if (typeof window !== `undefined`) {
      this.impFn().then(cmp => {
        this.setState({ Plot: createPlotlyComponent(cmp.default) })
      })
    }
  }

  render() {
    const { Plot } = this.state
    return <>{Plot && <Plot {...this.props} />}</>
  }
}
