import React, { Component } from 'react'

import './style.scss'

export default class ChartWrapper extends Component {
  componentDidMount() {
    setTimeout(() => {
      const resizeEvent = new Event('resize')
      window.dispatchEvent(resizeEvent)
    }, 300)
  }
  render() {
    const { children, title, caption } = this.props
    return (
      <div className="c-chart-wrapper">
        <div className="c-chart-wrapper__line my-4 breakout" />
        {title && <h3 className="c-chart-wrapper__title">{title}</h3>}
        <div className="breakout">{children}</div>
        {caption && (
          <div className="c-chart-wrapper__caption text-mid">{caption}</div>
        )}
        <div className="c-chart-wrapper__line my-4 breakout" />
      </div>
    )
  }
}
