import React, { Component } from 'react'

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'

const dataBarNeutralPosts = [
  { y: 'NEGATIVE Posts', a: 325, b: 158, c: 196 },
  { y: 'NEUTRAL Posts', a: 162, b: 74, c: 126 },
  { y: 'POSITIVE Posts', a: 727, b: 190, c: 170 },
]

export default class BarChartNeutralPostsHeadlines extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <section className="">
        <section className="">
          <div className="row text-center">
            <div className="col-sm-12">
              <div className="d-flex justify-content-center c-graph">
                <BarChart
                  width={600}
                  height={300}
                  data={dataBarNeutralPosts}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="y"
                    interval={0}
                    textAnchor="middle"
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis tick={{ fontSize: 12 }} />
                  <Tooltip />
                  <Bar dataKey="a" name="a" fill="#19B278" />
                  <Bar dataKey="b" name="b" fill="#3CC0C7" />
                  <Bar dataKey="c" name="c" fill="#F6A85A" />
                </BarChart>
              </div>
            </div>
          </div>
        </section>
      </section>
    )
  }
}
