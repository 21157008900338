import StartUpBarChart from '$data/blog/investments-euro-startups/components/StartUpBarChart';
import PerCityBarChart from '$data/blog/investments-euro-startups/components/PerCityBarChart';
import StartupsPerCityPieChart from '$data/blog/investments-euro-startups/components/StartupsPerCityPieChart';
import InvestmentsPerCityBubbleChart from '$data/blog/investments-euro-startups/components/InvestmentsPerCityBubbleChart';
import SumRaisedPerCityMapChart from '$data/blog/investments-euro-startups/components/SumRaisedPerCityMapChart';
import InvestmentsPerIndustryBubbleChart from '$data/blog/investments-euro-startups/components/InvestmentsPerIndustryBubbleChart';
import SumOfInvestmentPerIndustryBarChart from '$data/blog/investments-euro-startups/components/SumOfInvestmentPerIndustryBarChart';
import InvestmentsPerTechnologyBubbleChart1 from '$data/blog/investments-euro-startups/components/InvestmentsPerTechnologyBubbleChart1';
import InvestmentsPerTechnologyBubbleChart2 from '$data/blog/investments-euro-startups/components/InvestmentsPerTechnologyBubbleChart2';
import InvestmentsPerTechnologyBubbleChart3 from '$data/blog/investments-euro-startups/components/InvestmentsPerTechnologyBubbleChart3';
import InvestmentsPerCountryBarChart from '$data/blog/investments-euro-startups/components/InvestmentsPerCountryBarChart';
import PerCountryMapChart from '$data/blog/investments-euro-startups/components/PerCountryMapChart';
import * as React from 'react';
export default {
  StartUpBarChart,
  PerCityBarChart,
  StartupsPerCityPieChart,
  InvestmentsPerCityBubbleChart,
  SumRaisedPerCityMapChart,
  InvestmentsPerIndustryBubbleChart,
  SumOfInvestmentPerIndustryBarChart,
  InvestmentsPerTechnologyBubbleChart1,
  InvestmentsPerTechnologyBubbleChart2,
  InvestmentsPerTechnologyBubbleChart3,
  InvestmentsPerCountryBarChart,
  PerCountryMapChart,
  React
};