import React from 'react'
import Plot from '$components/PlotlyWrapper';

export default () => {
  return (
    <div
      className="container my-5 d-flex justify-content-center"
      style={{ height: '400px' }}
    >
      <Plot
        data={[
          {
            type: 'bar',
            x: [
              'Albania',
              'Malta',
              'Cyprus',
              'Bulgaria',
              'Serbia',
              'Slovenia',
              'Latvia',
              'Croatia',
              'Greece',
            ],
            y: [1, 1, 1, 1, 2, 2, 3, 3, 3],
            name: '0-3',
            marker: { color: '#ebf8f9' },
          },
          {
            type: 'bar',
            x: ['Hungary', 'Slovakia', 'Romania', 'Luxembourg'],
            y: [4, 4, 5, 6],
            name: '4-7',
            marker: { color: '#d8f2f3' },
          },
          {
            type: 'bar',
            x: [
              'Estonia',
              'Czech Republic',
              'Iceland',
              'Portugal',
              'Poland',
              'Austria',
            ],
            y: [9, 9, 10, 14, 20, 29],
            name: '8-30',
            marker: { color: '#b1e5e7' },
          },
          {
            type: 'bar',
            x: [
              'Norway',
              'Denmark',
              'Italy',
              'Ireland',
              'Switzerland',
              'Finland',
              'Netherlands',
              'Belgium',
            ],
            y: [38, 62, 63, 65, 79, 85, 92, 95],
            name: '31-100',
            marker: { color: '#8ad8dc' },
          },
          {
            type: 'bar',
            x: ['Spain', 'Sweden', 'Germany', 'France', 'United Kingdom'],
            y: [117, 246, 312, 534, 543],
            name: '>100',
            marker: { color: '#3cbec5' },
          },
        ]}
        layout={{
          width: '100%',
          height: '100%',
          title: '# Investments per country (All Data)',
        }}
      />
    </div>
  )
}
