import React from 'react'
import Plot from '$components/PlotlyWrapper';

export default () => {
  return (
    <div
      className="container my-5 d-flex justify-content-center"
      style={{ height: '400px' }}
    >
      <Plot
        data={[
          {
            type: 'scatter',
            x: [
              '2017-05-01',
              '2016-07-01',
              '2016-02-01',
              '2016-11-01',
              '2016-03-01',
              '2016-12-01',
              '2016-10-01',
              '2017-06-01',
              '2016-09-01',
              '2016-04-01',
              '2017-04-01',
              '2017-03-01',
              '2016-06-01',
              '2017-02-01',
              '2016-08-01',
              '2017-01-01',
              '2016-05-01',
            ],
            y: [
              4.0,
              2.0,
              2.0,
              3.0,
              3.0,
              3.0,
              6.0,
              4.0,
              6.0,
              5.0,
              2.0,
              4.0,
              2.0,
              2.0,
              2.0,
              2.0,
              1.0,
            ],
            mode: 'markers',
            name: 'Virtual Reality',
            opacity: 0.6,
            marker: {
              color: 'hsl(0.0,60%,60%)',
              size: [
                70,
                70,
                70,
                70,
                44.918,
                46.434,
                21.343125,
                52.4755,
                15.9102,
                17.0,
                42.046165,
                13.5,
                26.0085,
                22.71575,
                15.438225,
                14.243,
                10,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 11.1M',
              'Median: 92.0M',
              'Median: 45.0M',
              'Median: 18.2M',
              'Median: 4.5M',
              'Median: 4.6M',
              'Median: 2.1M',
              'Median: 5.2M',
              'Median: 1.6M',
              'Median: 1.7M',
              'Median: 4.2M',
              'Median: 1.4M',
              'Median: 2.6M',
              'Median: 2.3M',
              'Median: 1.5M',
              'Median: 1.4M',
              'Median: 0.8M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-04-01',
              '2016-04-01',
              '2016-12-01',
              '2016-07-01',
              '2017-03-01',
              '2016-10-01',
              '2016-05-01',
              '2016-08-01',
              '2017-01-01',
              '2017-05-01',
              '2016-06-01',
              '2016-03-01',
              '2017-06-01',
              '2016-09-01',
              '2016-02-01',
              '2016-11-01',
              '2017-02-01',
              '2016-01-01',
            ],
            y: [
              10.0,
              12.0,
              15.0,
              19.0,
              15.0,
              9.0,
              14.0,
              13.0,
              17.0,
              9.0,
              18.0,
              10.0,
              7.0,
              15.0,
              13.0,
              13.0,
              4.0,
              9.0,
            ],
            mode: 'markers',
            name: 'Connected Hardware',
            opacity: 0.6,
            marker: {
              color: 'hsl(30.0,60%,60%)',
              size: [
                31.020635,
                70,
                35.0,
                64.0,
                24.0,
                27.2235,
                34.8166,
                30.0,
                25.0,
                60.0,
                23.45361,
                51.33605,
                60.0,
                22.2895,
                45.0653,
                31.0,
                70,
                18.3752,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 3.1M',
              'Median: 12.2M',
              'Median: 3.5M',
              'Median: 6.4M',
              'Median: 2.4M',
              'Median: 2.7M',
              'Median: 3.5M',
              'Median: 3.0M',
              'Median: 2.5M',
              'Median: 6.0M',
              'Median: 2.3M',
              'Median: 5.1M',
              'Median: 6.0M',
              'Median: 2.2M',
              'Median: 4.5M',
              'Median: 3.1M',
              'Median: 12.9M',
              'Median: 1.8M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-01-01',
              '2016-10-01',
              '2017-03-01',
              '2017-04-01',
              '2016-06-01',
              '2016-03-01',
              '2016-07-01',
              '2016-01-01',
              '2016-11-01',
            ],
            y: [2.0, 1.0, 1.0, 2.0, 1.0, 1.0, 2.0, 1.0, 2.0],
            mode: 'markers',
            name: 'Autonomous Vehicles',
            opacity: 0.6,
            marker: {
              color: 'hsl(60.0,60%,60%)',
              size: [70, 70, 70, 59.97269, 70, 60.0, 23.491815, 41.0, 14.47995],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 192.1M',
              'Median: 30.0M',
              'Median: 16.7M',
              'Median: 6.0M',
              'Median: 8.0M',
              'Median: 6.0M',
              'Median: 2.3M',
              'Median: 4.1M',
              'Median: 1.4M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-11-01',
              '2017-04-01',
              '2016-10-01',
              '2016-04-01',
              '2017-05-01',
              '2017-06-01',
              '2017-03-01',
              '2016-02-01',
              '2016-01-01',
              '2016-05-01',
              '2017-02-01',
              '2017-01-01',
              '2016-12-01',
              '2016-09-01',
              '2016-06-01',
              '2016-03-01',
              '2016-07-01',
              '2016-08-01',
            ],
            y: [
              11.0,
              11.0,
              11.0,
              11.0,
              10.0,
              8.0,
              11.0,
              8.0,
              9.0,
              11.0,
              6.0,
              10.0,
              8.0,
              9.0,
              6.0,
              6.0,
              4.0,
              2.0,
            ],
            mode: 'markers',
            name: 'IoT',
            opacity: 0.6,
            marker: {
              color: 'hsl(90.0,60%,60%)',
              size: [
                28.3221,
                46.02619,
                21.04084,
                35.3896,
                29.75,
                39.5,
                12.0,
                24.0,
                30.0,
                11.0,
                31.5,
                20.37385,
                13.5,
                20.0,
                19.394056000000003,
                20.8,
                25.0,
                20.0,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 2.8M',
              'Median: 4.6M',
              'Median: 2.1M',
              'Median: 3.5M',
              'Median: 3.0M',
              'Median: 4.0M',
              'Median: 1.2M',
              'Median: 2.4M',
              'Median: 3.0M',
              'Median: 1.1M',
              'Median: 3.2M',
              'Median: 2.0M',
              'Median: 1.4M',
              'Median: 2.0M',
              'Median: 1.9M',
              'Median: 2.1M',
              'Median: 2.5M',
              'Median: 2.0M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-06-01',
              '2017-03-01',
              '2016-05-01',
              '2017-01-01',
              '2017-05-01',
              '2016-01-01',
              '2016-06-01',
              '2016-12-01',
              '2017-04-01',
              '2016-09-01',
              '2016-07-01',
              '2016-08-01',
              '2016-02-01',
              '2016-03-01',
              '2017-02-01',
              '2016-11-01',
              '2016-04-01',
              '2016-10-01',
            ],
            y: [
              23.0,
              13.0,
              12.0,
              12.0,
              10.0,
              8.0,
              9.0,
              10.0,
              10.0,
              8.0,
              6.0,
              3.0,
              7.0,
              9.0,
              6.0,
              6.0,
              9.0,
              7.0,
            ],
            mode: 'markers',
            name: 'App',
            opacity: 0.6,
            marker: {
              color: 'hsl(120.0,60%,60%)',
              size: [
                70,
                20.88,
                32.061,
                23.33665,
                39.12925,
                16.0783,
                19.89284,
                37.96856,
                15.33,
                19.178875,
                17.107365,
                30.0,
                30.0,
                19.3419,
                21.8381,
                20.48671,
                14.0,
                11.3465,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 8.3M',
              'Median: 2.1M',
              'Median: 3.2M',
              'Median: 2.3M',
              'Median: 3.9M',
              'Median: 1.6M',
              'Median: 2.0M',
              'Median: 3.8M',
              'Median: 1.5M',
              'Median: 1.9M',
              'Median: 1.7M',
              'Median: 3.0M',
              'Median: 3.0M',
              'Median: 1.9M',
              'Median: 2.2M',
              'Median: 2.0M',
              'Median: 1.4M',
              'Median: 1.1M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-05-01',
              '2016-10-01',
              '2016-11-01',
              '2017-03-01',
              '2017-04-01',
              '2016-07-01',
              '2016-06-01',
              '2016-03-01',
              '2016-05-01',
              '2017-01-01',
              '2017-06-01',
              '2016-04-01',
              '2016-01-01',
              '2016-08-01',
              '2016-12-01',
              '2016-09-01',
              '2017-02-01',
              '2016-02-01',
            ],
            y: [
              20.0,
              22.0,
              18.0,
              24.0,
              20.0,
              11.0,
              18.0,
              14.0,
              9.0,
              20.0,
              15.0,
              8.0,
              12.0,
              11.0,
              8.0,
              11.0,
              12.0,
              9.0,
            ],
            mode: 'markers',
            name: 'Artificial Intelligence',
            opacity: 0.6,
            marker: {
              color: 'hsl(150.0,60%,60%)',
              size: [
                30.369,
                30.568,
                34.46,
                31.91098,
                22.1289,
                18.088794,
                19.4159,
                29.3419,
                29.13632,
                20.0,
                22.5,
                51.48254,
                18.6876,
                32.99608,
                12.5,
                18.54208,
                28.8879,
                14.3,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 3.0M',
              'Median: 3.1M',
              'Median: 3.4M',
              'Median: 3.2M',
              'Median: 2.2M',
              'Median: 1.8M',
              'Median: 1.9M',
              'Median: 2.9M',
              'Median: 2.9M',
              'Median: 2.0M',
              'Median: 2.2M',
              'Median: 5.1M',
              'Median: 1.9M',
              'Median: 3.3M',
              'Median: 1.2M',
              'Median: 1.9M',
              'Median: 2.9M',
              'Median: 1.4M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-03-01',
              '2016-01-01',
              '2017-05-01',
              '2017-03-01',
              '2016-06-01',
              '2016-11-01',
              '2016-10-01',
              '2016-02-01',
              '2017-04-01',
              '2016-05-01',
              '2017-02-01',
              '2017-01-01',
              '2016-04-01',
              '2016-09-01',
              '2017-06-01',
              '2016-07-01',
              '2016-08-01',
              '2016-12-01',
            ],
            y: [
              18.0,
              23.0,
              5.0,
              14.0,
              18.0,
              10.0,
              18.0,
              15.0,
              12.0,
              15.0,
              18.0,
              10.0,
              16.0,
              10.0,
              10.0,
              8.0,
              5.0,
              7.0,
            ],
            mode: 'markers',
            name: 'SaaS',
            opacity: 0.6,
            marker: {
              color: 'hsl(180.0,60%,60%)',
              size: [
                24.57328,
                41.3442,
                70,
                23.17256,
                28.37385,
                48.216775,
                26.225,
                17.768604,
                48.5,
                12.0,
                28.12625,
                20.0,
                20.0,
                25.08645,
                20.0,
                16.04424,
                40.0,
                30.0,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 2.5M',
              'Median: 4.1M',
              'Median: 8.0M',
              'Median: 2.3M',
              'Median: 2.8M',
              'Median: 4.8M',
              'Median: 2.6M',
              'Median: 1.8M',
              'Median: 4.8M',
              'Median: 1.2M',
              'Median: 2.8M',
              'Median: 2.0M',
              'Median: 2.0M',
              'Median: 2.5M',
              'Median: 2.0M',
              'Median: 1.6M',
              'Median: 4.0M',
              'Median: 3.0M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-03-01',
              '2017-02-01',
              '2016-05-01',
              '2016-04-01',
              '2016-02-01',
              '2017-04-01',
              '2016-06-01',
              '2017-01-01',
              '2016-09-01',
              '2017-05-01',
              '2017-03-01',
              '2016-10-01',
              '2016-11-01',
              '2016-07-01',
              '2016-01-01',
              '2016-08-01',
              '2017-06-01',
              '2016-12-01',
            ],
            y: [
              13.0,
              20.0,
              12.0,
              13.0,
              16.0,
              15.0,
              15.0,
              14.0,
              16.0,
              12.0,
              10.0,
              9.0,
              14.0,
              15.0,
              6.0,
              7.0,
              7.0,
              7.0,
            ],
            mode: 'markers',
            name: 'Data Analytics',
            opacity: 0.6,
            marker: {
              color: 'hsl(210.0,60%,60%)',
              size: [
                32.2365,
                29.13915,
                55.5,
                12.6887,
                34.195315,
                29.1772,
                30.0,
                50.0,
                25.72006,
                47.56425,
                41.5,
                39.90456,
                18.10387,
                10.0,
                36.5,
                25.3816,
                30.0,
                12.00017,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 3.2M',
              'Median: 2.9M',
              'Median: 5.6M',
              'Median: 1.3M',
              'Median: 3.4M',
              'Median: 2.9M',
              'Median: 3.0M',
              'Median: 5.0M',
              'Median: 2.6M',
              'Median: 4.8M',
              'Median: 4.2M',
              'Median: 4.0M',
              'Median: 1.8M',
              'Median: 1.0M',
              'Median: 3.6M',
              'Median: 2.5M',
              'Median: 3.0M',
              'Median: 1.2M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-05-01',
              '2016-09-01',
              '2016-06-01',
              '2016-11-01',
              '2016-05-01',
              '2017-04-01',
              '2016-08-01',
              '2016-10-01',
              '2016-04-01',
              '2016-03-01',
              '2017-03-01',
            ],
            y: [1.0, 6.0, 1.0, 1.0, 2.0, 1.0, 1.0, 2.0, 1.0, 1.0, 1.0],
            mode: 'markers',
            name: '3D printing',
            opacity: 0.6,
            marker: {
              color: 'hsl(240.0,60%,60%)',
              size: [
                70,
                32.8318,
                70,
                70,
                52.0795,
                70.0,
                62.8369,
                16.343125,
                32.09724,
                30.0,
                14.66609,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 66.0M',
              'Median: 3.3M',
              'Median: 16.9M',
              'Median: 15.2M',
              'Median: 5.2M',
              'Median: 7.0M',
              'Median: 6.3M',
              'Median: 1.6M',
              'Median: 3.2M',
              'Median: 3.0M',
              'Median: 1.5M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-03-01',
              '2017-06-01',
              '2016-06-01',
              '2016-11-01',
              '2017-04-01',
              '2017-02-01',
              '2016-03-01',
              '2016-01-01',
              '2017-01-01',
              '2016-05-01',
              '2016-12-01',
              '2016-10-01',
              '2016-04-01',
              '2016-08-01',
              '2016-09-01',
            ],
            y: [
              9.0,
              1.0,
              2.0,
              4.0,
              4.0,
              4.0,
              3.0,
              4.0,
              3.0,
              3.0,
              4.0,
              1.0,
              1.0,
              1.0,
              1.0,
            ],
            mode: 'markers',
            name: 'API',
            opacity: 0.6,
            marker: {
              color: 'hsl(270.0,60%,60%)',
              size: [
                19.0,
                70,
                70,
                64.447,
                40.25,
                16.102135,
                23.0,
                14.85008,
                52.465,
                10,
                20.0,
                12.5,
                10.0,
                10.0,
                10.0,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 1.9M',
              'Median: 47.2M',
              'Median: 17.5M',
              'Median: 6.4M',
              'Median: 4.0M',
              'Median: 1.6M',
              'Median: 2.3M',
              'Median: 1.5M',
              'Median: 5.2M',
              'Median: 0.9M',
              'Median: 2.0M',
              'Median: 1.2M',
              'Median: 1.0M',
              'Median: 1.0M',
              'Median: 1.0M',
            ],
          },
        ]}
        layout={{
          width: '100%',
          height: '100%',
          title: '# Investments per Technology (Size is Median of investment)',
          xaxis: { title: 'Date' },
          yaxis: { title: '# Investments' },
        }}
      />
    </div>
  )
}
