import React, { Component } from 'react'
import Timer from '../Timer'

import './style.scss'
import Img from 'gatsby-image'

// moved to nuke carousel because flickity
// gave a lot of issues with the modular markdown system
import Carousel from 'nuka-carousel'

import { get } from 'lodash'

export default class ImageSlider extends Component {
  constructor(props) {
    super(props)
    const { data } = props

    this.slideDuration = 5000 // in ms
    this.slideStandStill = 500
    this.slideTimeout = null
    this.countdownTimeout = null
    this.portrait = props.portrait

    this.state = {
      selectedIndex: 0,
      progress: 0,
    }

    this.startCountDown = this.startCountDown.bind(this)
  }

  componentDidMount = () => {
    // get the title attributes

    const children = Array.from(this.sliderRef.getChildNodes())
    const captions = children.map(child => child.querySelector('img').title)
    const maxIndex = children.length

    this.setState({
      maxIndex,
      captions,
    })

    this.startCountDown()

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 1000)
  }

  componentWillUnmount() {
    this.slideTimeout && clearTimeout(this.slideTimeout)
    this.countdownTimeout && clearTimeout(this.countdownTimeout)
  }

  startCountDown() {
    this.slideTimeout && clearTimeout(this.slideTimeout)
    this.setState({
      progress: 100,
    })

    this.slideTimeout = setTimeout(() => {
      this.nextSlide()
    }, this.slideDuration)
  }

  nextSlide() {
    let index = this.state.selectedIndex + 1

    if (index >= this.state.maxIndex) {
      index = 0
    }

    this.setState({ selectedIndex: index })
  }

  render() {
    const { children } = this.props
    const images = get(this.props, 'data.frontmatter.slider_images', null)

    return (
      <div>
        <div className="c-image-slider position-relative shadow">
          <Carousel
            ref={ref => (this.sliderRef = ref)}
            edgeEasing="easePolyIn"
            dragging={false}
            heightMode="first"
            pauseOnHover={false}
            renderBottomCenterControls={null}
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
            slideIndex={this.state.selectedIndex}
            afterSlide={index => {
              this.setState({
                // selectedIndex: index,
                progress: 0,
              })
              this.countdownTimeout && clearTimeout(this.countdownTimeout)
              this.countdownTimeout = setTimeout(
                this.startCountDown,
                this.slideStandStill
              )
            }}
          >
            {!images && children}
            {images &&
              images.map(item => {
                return (
                  <Img
                    onLoad={() => {
                      window.dispatchEvent(new Event('resize'))
                    }}
                    key={item.file.id}
                    fluid={
                      this.portrait
                        ? item.file.childImageSharp.portrait
                        : item.file.childImageSharp.landscape
                    }
                    alt={item.description}
                    title={item.description}
                  />
                )
              })}
          </Carousel>
          <footer className="c-image-slider__info bg-white mr-0 mr-lg-3 px-2 px-sm-3 py-2">
            <div className="c-image-slider__text ">
              <div className="c-image-slider__index text-uppercase mb-1">
                <strong>{this.state.selectedIndex + 1}</strong> of{' '}
                {this.state.maxIndex}
              </div>
              <div className="c-image-slider__description">
                {this.state.captions &&
                  this.state.captions[this.state.selectedIndex]}
              </div>
            </div>
            <div className="c-image-slider__timer ">
              <Timer
                darkMode
                branded
                progress={this.state.progress}
                number={this.state.selectedIndex + 1}
                duration={this.slideDuration}
              />
            </div>
          </footer>
        </div>{' '}
      </div>
    )
  }
}
