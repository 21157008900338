import React from 'react'
import Plot from '$components/PlotlyWrapper';

export default () => {
  return (
    <div
      className="container my-5 d-flex justify-content-center"
      style={{ height: '400px' }}
    >
      <Plot
        data={[
          {
            type: 'pie',
            labels: [
              'London',
              'Paris',
              'Berlin',
              'Stockholm',
              'Barcelona',
              'Dublin',
              'Munich',
              'Amsterdam',
              'Copenhagen',
              'Milan',
              'Helsinki',
              'Cambridge',
              'Madrid',
              'Oslo',
              'Zurich',
              'Gothenburg',
              'Brussels',
              'Ghent',
              'Lausanne',
              'Hamburg',
              '-OTHERS-',
            ],
            values: [
              353.0,
              346.0,
              165.0,
              157.0,
              64.0,
              52.0,
              50.0,
              45.0,
              41.0,
              39.0,
              39.0,
              36.0,
              32.0,
              30.0,
              25.0,
              24.0,
              23.0,
              22.0,
              21.0,
              21.0,
              876.0,
            ],
            marker: {
              colors: [
                'hsl(0.0,60%,60%)',
                'hsl(15.652173913,60%,60%)',
                'hsl(31.3043478261,60%,60%)',
                'hsl(46.9565217391,60%,60%)',
                'hsl(62.6086956522,60%,60%)',
                'hsl(78.2608695652,60%,60%)',
                'hsl(93.9130434783,60%,60%)',
                'hsl(109.565217391,60%,60%)',
                'hsl(125.217391304,60%,60%)',
                'hsl(140.869565217,60%,60%)',
                'hsl(156.52173913,60%,60%)',
                'hsl(172.173913043,60%,60%)',
                'hsl(187.826086957,60%,60%)',
                'hsl(203.47826087,60%,60%)',
                'hsl(219.130434783,60%,60%)',
                'hsl(234.782608696,60%,60%)',
                'hsl(250.434782609,60%,60%)',
                'hsl(266.086956522,60%,60%)',
                'hsl(281.739130435,60%,60%)',
                'hsl(297.391304348,60%,60%)',
                'hsl(313.043478261,60%,60%)',
                'hsl(328.695652174,60%,60%)',
                'hsl(344.347826087,60%,60%)',
                'hsl(360.0,60%,60%)',
              ],
            },
            hole: 0.7,
          },
        ]}
        layout={{
          width: '100%',
          height: '100%',
          title: '# Startups per "City"',
        }}
      />
    </div>
  )
}
