import React, { Component } from 'react'

import './style.scss'
import logoWhite from "../../gfx/logo-white.svg";

export default class MiniQuote extends Component {
  render() {
    const { children, title, introTitle, author, smallMargins } = this.props
    return (
      <div className={` "c-mini-quote my-2  ${smallMargins ? 'my-md-1 my-lg-3' : 'my-md-3 my-lg-5'}  px-sm-3 `}>
        <div className="c-mini-quote__inner pl-1 pl-sm-3 py-1">
          {introTitle && (
            <h5 className="c-mini-quote__intro-title text-secondary">
              {introTitle}
            </h5>
          )}
          {title && <h3 className="c-mini-quote__title">{title}</h3>}
          <div className="c-mini-quote__text text-mid">{children}</div>
            <h5 className="c-mini-quote__author text-secondary">
              {author}
            </h5>
        </div>
      </div>
    )
  }
}
