import React from 'react'
import Plot from '$components/PlotlyWrapper'

export default () => {
  return (
    <Plot
      data={[
        {
          type: 'scatter',
          x: [
            '2016-09-01',
            '2017-06-01',
            '2016-10-01',
            '2017-03-01',
            '2017-01-01',
            '2016-06-01',
            '2016-04-01',
            '2016-01-01',
            '2017-02-01',
            '2016-02-01',
            '2016-03-01',
            '2017-04-01',
            '2016-05-01',
            '2016-12-01',
            '2016-11-01',
            '2017-05-01',
            '2016-07-01',
            '2016-08-01',
          ],
          y: [
            38.0,
            35.0,
            34.0,
            27.0,
            26.0,
            25.0,
            20.0,
            20.0,
            18.0,
            17.0,
            16.0,
            16.0,
            16.0,
            16.0,
            15.0,
            15.0,
            10.0,
            5.0,
          ],
          mode: 'markers',
          name: 'Paris',
          opacity: 0.6,
          marker: {
            color: 'hsl(0.0,60%,60%)',
            size: [
              30.0,
              30.0,
              30.0,
              18.0,
              20.0,
              20.0,
              33.54862,
              27.63295,
              27.5,
              15.0,
              36.0,
              40.0,
              16.5,
              31.5,
              20.0,
              40.0,
              13.0,
              70,
            ],
            line: { width: 1, color: '#adadad' },
          },
          text: [
            'Median: 3.0M',
            'Median: 3.0M',
            'Median: 3.0M',
            'Median: 1.8M',
            'Median: 2.0M',
            'Median: 2.0M',
            'Median: 3.4M',
            'Median: 2.8M',
            'Median: 2.8M',
            'Median: 1.5M',
            'Median: 3.6M',
            'Median: 4.0M',
            'Median: 1.6M',
            'Median: 3.2M',
            'Median: 2.0M',
            'Median: 4.0M',
            'Median: 1.3M',
            'Median: 7.4M',
          ],
        },
        {
          type: 'scatter',
          x: [
            '2016-10-01',
            '2017-03-01',
            '2017-05-01',
            '2017-06-01',
            '2016-11-01',
            '2017-02-01',
            '2016-01-01',
            '2016-07-01',
            '2016-06-01',
            '2017-04-01',
            '2016-02-01',
            '2016-09-01',
            '2016-03-01',
            '2016-04-01',
            '2016-05-01',
            '2016-08-01',
            '2017-01-01',
            '2016-12-01',
          ],
          y: [
            32.0,
            28.0,
            27.0,
            27.0,
            25.0,
            25.0,
            24.0,
            23.0,
            23.0,
            22.0,
            21.0,
            21.0,
            21.0,
            19.0,
            19.0,
            18.0,
            17.0,
            10.0,
          ],
          mode: 'markers',
          name: 'London',
          opacity: 0.6,
          marker: {
            color: 'hsl(51.4285714286,60%,60%)',
            size: [
              22.7664285508,
              34.10368,
              57.25,
              28.48675,
              22.24,
              37.44678,
              53.019725,
              35.0571,
              37.9017,
              34.988035,
              42.63312,
              20.0,
              32.2365,
              18.8454,
              40.60384,
              50.893825,
              27.61248,
              26.25084,
            ],
            line: { width: 1, color: '#adadad' },
          },
          text: [
            'Median: 2.3M',
            'Median: 3.4M',
            'Median: 5.7M',
            'Median: 2.8M',
            'Median: 2.2M',
            'Median: 3.7M',
            'Median: 5.3M',
            'Median: 3.5M',
            'Median: 3.8M',
            'Median: 3.5M',
            'Median: 4.3M',
            'Median: 2.0M',
            'Median: 3.2M',
            'Median: 1.9M',
            'Median: 4.1M',
            'Median: 5.1M',
            'Median: 2.8M',
            'Median: 2.6M',
          ],
        },
        {
          type: 'scatter',
          x: [
            '2017-05-01',
            '2016-03-01',
            '2017-01-01',
            '2016-06-01',
            '2017-06-01',
            '2016-09-01',
            '2017-03-01',
            '2016-01-01',
            '2016-02-01',
            '2017-04-01',
            '2016-04-01',
            '2016-05-01',
            '2016-12-01',
            '2017-02-01',
            '2016-08-01',
            '2016-11-01',
            '2016-07-01',
            '2016-10-01',
          ],
          y: [
            17.0,
            15.0,
            14.0,
            12.0,
            11.0,
            11.0,
            11.0,
            10.0,
            10.0,
            10.0,
            10.0,
            9.0,
            9.0,
            9.0,
            9.0,
            8.0,
            7.0,
            6.0,
          ],
          mode: 'markers',
          name: 'Berlin',
          opacity: 0.6,
          marker: {
            color: 'hsl(102.857142857,60%,60%)',
            size: [
              70,
              12.0,
              60.442,
              47.5,
              30.0,
              16.11575,
              30.0,
              26.0783,
              23.5,
              35.6124,
              33.9448,
              39.8133,
              21.86487,
              42.0,
              44.8835,
              36.0,
              17.0,
              42.5,
            ],
            line: { width: 1, color: '#adadad' },
          },
          text: [
            'Median: 8.0M',
            'Median: 1.2M',
            'Median: 6.0M',
            'Median: 4.8M',
            'Median: 3.0M',
            'Median: 1.6M',
            'Median: 3.0M',
            'Median: 2.6M',
            'Median: 2.4M',
            'Median: 3.6M',
            'Median: 3.4M',
            'Median: 4.0M',
            'Median: 2.2M',
            'Median: 4.2M',
            'Median: 4.5M',
            'Median: 3.6M',
            'Median: 1.7M',
            'Median: 4.2M',
          ],
        },
        {
          type: 'scatter',
          x: [
            '2016-10-01',
            '2016-05-01',
            '2017-05-01',
            '2017-03-01',
            '2017-02-01',
            '2016-08-01',
            '2016-11-01',
            '2017-06-01',
            '2016-12-01',
            '2017-04-01',
            '2016-06-01',
            '2016-09-01',
            '2016-07-01',
            '2016-01-01',
            '2017-01-01',
            '2016-02-01',
            '2016-03-01',
            '2016-04-01',
          ],
          y: [
            16.0,
            16.0,
            16.0,
            14.0,
            12.0,
            12.0,
            11.0,
            11.0,
            11.0,
            10.0,
            10.0,
            10.0,
            9.0,
            8.0,
            8.0,
            8.0,
            8.0,
            5.0,
          ],
          mode: 'markers',
          name: 'Stockholm',
          opacity: 0.6,
          marker: {
            color: 'hsl(154.285714286,60%,60%)',
            size: [
              13.975,
              27.23,
              24.3109,
              18.342,
              25.2829,
              15.978526,
              14.3304,
              20.0,
              37.851,
              15.6835,
              49.4204,
              14.15205,
              30.0,
              34.4535,
              30.939475,
              24.12285,
              16.0,
              12.96,
            ],
            line: { width: 1, color: '#adadad' },
          },
          text: [
            'Median: 1.4M',
            'Median: 2.7M',
            'Median: 2.4M',
            'Median: 1.8M',
            'Median: 2.5M',
            'Median: 1.6M',
            'Median: 1.4M',
            'Median: 2.0M',
            'Median: 3.8M',
            'Median: 1.6M',
            'Median: 4.9M',
            'Median: 1.4M',
            'Median: 3.0M',
            'Median: 3.4M',
            'Median: 3.1M',
            'Median: 2.4M',
            'Median: 1.6M',
            'Median: 1.3M',
          ],
        },
        {
          type: 'scatter',
          x: [
            '2017-05-01',
            '2017-03-01',
            '2016-06-01',
            '2016-09-01',
            '2016-04-01',
            '2017-02-01',
            '2016-07-01',
            '2017-01-01',
            '2016-03-01',
            '2016-02-01',
            '2016-05-01',
            '2016-08-01',
            '2016-10-01',
            '2016-12-01',
            '2017-06-01',
            '2016-11-01',
            '2017-04-01',
            '2016-01-01',
          ],
          y: [
            8.0,
            7.0,
            7.0,
            6.0,
            6.0,
            4.0,
            4.0,
            4.0,
            3.0,
            3.0,
            3.0,
            3.0,
            3.0,
            3.0,
            3.0,
            2.0,
            2.0,
            1.0,
          ],
          mode: 'markers',
          name: 'Barcelona',
          opacity: 0.6,
          marker: {
            color: 'hsl(205.714285714,60%,60%)',
            size: [
              30.0,
              10.0,
              13.0,
              10.0,
              13.25,
              70,
              23.0,
              22.5,
              30.0,
              70,
              70,
              15.0,
              36.0,
              30.0,
              20.0,
              10,
              21.0,
              18.0,
            ],
            line: { width: 1, color: '#adadad' },
          },
          text: [
            'Median: 3.0M',
            'Median: 1.0M',
            'Median: 1.3M',
            'Median: 1.0M',
            'Median: 1.3M',
            'Median: 7.5M',
            'Median: 2.3M',
            'Median: 2.2M',
            'Median: 3.0M',
            'Median: 8.9M',
            'Median: 10.7M',
            'Median: 1.5M',
            'Median: 3.6M',
            'Median: 3.0M',
            'Median: 2.0M',
            'Median: 1.0M',
            'Median: 2.1M',
            'Median: 1.8M',
          ],
        },
      ]}
      // to make this responsive;
      // add useResizeHandler true attribute
      // set width and height to 100%
      // add autosize true property to layout object
      useResizeHandler={true}
      style={{ width: '100%', height: '100%' }}
      layout={{
        // title: '# Investments per City (Size is Median of investment)',
        xaxis: { title: 'Date' },
        yaxis: { title: '# Investments' },
        autosize: true,
      }}
    />
  )
}
