import Breakout from '$components/Breakout';
import ImageSlider from '$components/ImageSlider';
import MassiveQuote from '$components/MassiveQuote';
import MiniQuote from '$components/MiniQuote';
import ChartWrapper from '$components/ChartWrapper';
import SideQuote from '$components/SideQuote';
import TwoCol, { Col } from '$components/TwoCol';
import Conclusions from '$components/Conclusions';
import InvestmentsPerCityBubbleChart from "../../../../src/data/applications/mdx-demo/components/InvestmentsPerCityBubbleChart";
import * as React from 'react';
export default {
  Breakout,
  ImageSlider,
  MassiveQuote,
  MiniQuote,
  ChartWrapper,
  SideQuote,
  TwoCol,
  Col,
  Conclusions,
  InvestmentsPerCityBubbleChart,
  React
};