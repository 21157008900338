import React from 'react'

import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import './style.scss'

import { ReactComponent as Arrow } from '../../gfx/arrow-right.svg'

const Timer = ({
  number,
  progress,
  branded,
  darkMode,
  arrowNext,
  arrowPrev,
  className,
  duration,
}) => {
  const formatNumber = number => {
    let formattedNumber = number + ''
    if (formattedNumber.length < 2) formattedNumber = '0' + formattedNumber
    return formattedNumber
  }

  return (
    <div
      className={`c-timer ${(branded || darkMode) &&
        'c-timer--branded'} ${className} ${progress === 0 && 'is-zero'}`}
    >
      <CircularProgressbar
        value={progress}
        styles={{
          path: { transition: `stroke-dashoffset ${duration}ms linear` },
        }}
      />
      {arrowNext && (
        <div className="c-timer__arrow">
          <Arrow className="d-block" />
        </div>
      )}
      {arrowPrev && (
        <div className="c-timer__arrow">
          <Arrow className="d-block" style={{ transform: 'scaleX(-1)' }} />
        </div>
      )}
      {!arrowNext && !arrowPrev && (
        <span className={`c-timer__number ${darkMode && 'text-dark'}`}>
          {formatNumber(number)}
        </span>
      )}
    </div>
  )
}

export default Timer
