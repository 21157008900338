import React from 'react'
import Plot from '$components/PlotlyWrapper';

export default () => {
  return (
    <div
      className="container my-5 d-flex justify-content-center"
      style={{ height: '400px' }}
    >
      <Plot
        data={[
          {
            type: 'bar',
            x: [
              'FinTech',
              'HealthTech',
              'eCommerce',
              'Gaming',
              'MadTech',
              'Travel',
              'Mobility',
              'Software development',
              'HRTech',
              'PropTech',
              'Business Intelligence',
              'IT Services',
              'MediaTech',
              'Utilities',
              'Telecom',
              'Cybersecurity',
              'Manufacturing',
              'Supply Chain',
              'EdTech',
              'Retail',
              '-OTHERS-',
            ],
            y: [
              6406597437.3695545,
              1832037389.0984,
              1810452643.37855,
              1242571215.9,
              1156527097.75,
              1151720901.76976,
              593761662.8366001,
              585175929.5,
              582379806.68592,
              570553654.262,
              500752075.3,
              465103086.88,
              444908242.8,
              430309193.7,
              413644382.2,
              407840697.3,
              373698200.4,
              332800500.8,
              253830429.4596,
              241753706.75,
              6814461503.562305,
            ],
            marker: { color: '#3CBEC5' },
          },
        ]}
        layout={{
          width: '100%',
          height: '100%',
          title: 'Sum of Investment per Industry',
        }}
      />
    </div>
  )
}
