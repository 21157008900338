import React, { Component } from 'react'
import DSPieChart from './DSPieChart'

const backlinkoData = [
  { name: 'External Links', value: 42.4 },
  { name: 'Headline Length', value: 29.1 },
  { name: 'Week Day', value: 28.5 },
]

const conversionxlData = [
  { name: 'Headline Neutrality', value: 39.2 },
  { name: '# Images', value: 11.4 },
  { name: 'Week Count', value: 49.4 },
]

const IWTData = [
  { name: 'Headline Length', value: 34.1 },
  { name: 'Word Count', value: 26.5 },
  { name: 'Week Day', value: 25.6 },
  { name: '# Images', value: 13.7 },
]

export default class PredictiveAnalyticsGraph extends Component {
  render() {
    return (
      <div className="container">
        <div className="row py-5">
          <div className="col-sm-6 col-md-4 text-center">
            <h3>Backlinko</h3>
            <div className="d-flex justify-content-center">
              <div className="c-graph c-graph--pie mt-3">
                <DSPieChart data={backlinkoData} />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 text-center">
            <h3>ConversionXL</h3>
            <div className="d-flex justify-content-center">
              <div className="c-graph c-graph--pie mt-3">
                <DSPieChart data={conversionxlData} />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 text-center">
            <h3>IWT</h3>
            <div className="d-flex justify-content-center">
              <div className="c-graph c-graph--pie mt-3">
                <DSPieChart data={IWTData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
