import React, { Component } from 'react'

export default class Conclusions extends Component {
  render() {
    const { children, title } = this.props
    return (
      <div className="c-conclusions p-2 p-md-3 p-lg-5 my-2 my-md-3 my-lg-5 bg-offwhite">
        <h2>{title}</h2>
        <div className="c-conslusions__text">{children}</div>
      </div>
    )
  }
}
