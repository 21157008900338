import React, { Component } from 'react'

export default class TwoCol extends Component {
  render() {
    return <div className="row breakout my-md-4">{this.props.children}</div>
  }
}

export class Col extends Component {
  render() {
    return <div className="col-sm-6">{this.props.children}</div>
  }
}
