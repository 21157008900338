import React, { Component } from 'react'
import axios from 'axios'
import { withPrefix } from 'gatsby'
import { uniq } from 'lodash'
import Plot from '$components/PlotlyWrapper'

export default class TweetsRangeChart extends Component {
  state = { loaded: false }
  async componentDidMount() {
    try {
      const { data } = await axios.get(withPrefix('/data/weekdays.json'))
      const sites = uniq(data.map(item => item.site))
      const splitData = []

      const weekdays = {
        1: 'Mon',
        2: 'Tue',
        3: 'Wed',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat',
        7: 'Sun',
      }

      const colors = ['#EA1162', '#F37A4E', '#19B278', '#F6A85A', '#3CC0C7']

      // split the data per label so we easily can add a legend for each
      sites.forEach((site, i) => {
        const siteData = data.filter(item => item.site === site)
        const sortedSiteData = siteData.sort((a, b) => a.weekday - b.weekday)

        const x = sortedSiteData.map(item => item.tweets)
        const y = sortedSiteData.map(item => weekdays[item.weekday])

        // get the color from the colors array,since 0%0 returns NaN provide a 0 as fallback
        const color = colors[i % colors.length || 0]

        const plotData = {
          name: site,
          x,
          y,
          // text: site,
          type: 'scatter',
          mode: 'markers',
          marker: {
            color: color,
            size: 10,
          },
        }

        splitData.push(plotData)
      })

      this.setState({
        data: splitData,
        loaded: true,
      })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { loaded, data } = this.state
    return (
      <div>
        <div id="chart">
          {loaded ? (
            <Plot
              style={{ width: '100%', height: '320px' }}
              data={data}
              useResizeHandler={true}
              layout={{
                autosize: true,
                showlegend: true,
                legend: { orientation: 'h' },
                hovermode: 'closest',
                // width: '100%',
                // height: '320',
                yaxis: {},
              }}
            />
          ) : (
            <p>Loading....</p>
          )}
        </div>
      </div>
    )
  }
}
