import bookWhite from '$gfx/book-white.svg';
import controller from '$gfx/controller.svg';
import controllerGrey from '$gfx/controller-grey.svg';
import stepSliderImage from '$data/products/stepslider/stepslider.jpg';
import uploadmovieImage from '$data/products/stepslider/upload.gif';
import selectKPIImage from '$data/products/stepslider/selectKPI.gif';
import startStoryImage from '$data/products/stepslider/startstory.gif';
import resultsStoryImage from '$data/products/stepslider/results.gif';
import communicateStoryImage from '$data/products/stepslider/communicate.gif';
import engineers from '$data/products/forwho/engineers.svg';
import experts from '$data/products/forwho/experts.svg';
import scientists from '$data/products/forwho/scientists.svg';
import * as React from 'react';
export default {
  bookWhite,
  controller,
  controllerGrey,
  stepSliderImage,
  uploadmovieImage,
  selectKPIImage,
  startStoryImage,
  resultsStoryImage,
  communicateStoryImage,
  engineers,
  experts,
  scientists,
  React
};