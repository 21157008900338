import React, { Component } from 'react'
import { PieChart, Pie, Cell, Label } from 'recharts'

const COLORS = [
  '#EA1162',
  '#F37A4E',
  '#19B278',
  '#3CC0C7',
  '#F6A85A',
  '#F1768B',
]

const CustomLabel = ({ viewBox, value1, value2 }) => {
  const { cx, cy } = viewBox
  return (
    <text
      x={cx}
      y={cy}
      fill="#3d405c"
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan x="50%" dy="-.5em" fontSize="18" fontWeight="bold">
        {value1}
      </tspan>
      <tspan x="50%" dy="1.3em" fontSize="16">
        {value2}%
      </tspan>
    </text>
  )
}

export default class DSPieChart extends Component {
  constructor(props) {
    super(props)
    this.onPieEnter = this.onPieEnter.bind(this)
  }

  state = {
    activeIndex: 0,
  }

  onPieEnter(data, index) {
    this.setState({
      activeIndex: index,
    })
  }

  render() {
    const { data } = this.props
    return (
      <PieChart width={200} height={200}>
        <Pie
          onMouseEnter={this.onPieEnter}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={100}
          innerRadius={80}
          paddingAngle={5}
          dataKey="value"
        >
          <Label
            width={30}
            position="center"
            content={
              <CustomLabel
                value1={data[this.state.activeIndex].name}
                value2={data[this.state.activeIndex].value}
              />
            }
          />

          {data.map((entry, index) => (
            <Cell
              key={index}
              fill={COLORS[index % COLORS.length]}
              className={this.state.activeIndex === index && 'is-active'}
            />
          ))}
        </Pie>
      </PieChart>
    )
  }
}
