import React, { Component } from 'react'

import './style.scss'

export default class SideQuote extends Component {
  render() {
    return (
      <div className="c-side-quote pr-sm-3 text-primary">
        “{this.props.children}”
      </div>
    )
  }
}
