import React, { Component } from 'react'
import { withPrefix } from 'gatsby'
import { Typeahead } from 'react-bootstrap-typeahead' // ES2015

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'

import axios from 'axios'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import './style.scss'

export default class WordsTableSearch extends Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  state = {
    hasLoaded: false,
    word: '',
  }
  componentDidMount() {
    // load the necessary json files
    // all the data files reside in a static directory to prevent them from getting picked up by webpack and bloating the bundle size
    // axios.get(withPrefix('/data/headlines.json')),
    //   axios.get(withPrefix('/data/vocabularyHead.json')),
    axios
      .get(withPrefix('/data/words6w4000.json'))
      .then(wordsRes => {
        // turn the words into an array for use with the autosuggest component
        const wordSuggestions = Object.entries(wordsRes.data).map(
          suggestion => suggestion[0]
        )

        this.setState({
          words: wordsRes.data,
          wordSuggestions: wordSuggestions,
          hasLoaded: true,
          word: 'example',
          wordData: wordsRes.data['example'],
        })
      })

      .catch(e => console.log(e) && this.setState({ hasFailed: true }))
  }

  componentDidUpdate(prevProps, prevState) {
    // check if there's a new word selected, if so recalculate the similarity
    if (this.state.word != prevState.word) {
      this.setState({ wordData: this.state.words[this.state.word] })
    }
  }

  onSubmit(value) {
    this.setState({ word: value })
  }

  render() {
    const { word, wordData, wordSuggestions } = this.state
    // format the data to percentages

    return (
      <section className="">
        {this.state.hasLoaded ? (
          <>
            <div className="row pt-5 pb-3">
              <div className="col-sm-12">
                <div className="text-center">
                  <h3>Type any word here:</h3>
                  <div className="text-left d-flex justify-content-center">
                    <div className="c-typeahead">
                      <Typeahead
                        options={wordSuggestions}
                        onChange={this.onSubmit}
                        placeholder="Enter your search term"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-grid" id="chrt">
              <div className="col-md-12">
                <div className="text" id="tbl">
                  <div className="text-700">
                    <table id="records_table" className="table table-striped">
                      <thead>
                        <tr>
                          <th className="col-md-3">
                            <h3>Top ten similar words to "{word}"</h3>
                          </th>
                          <th className="col-md-2">
                            <h3>% of similarity</h3>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.wordData.value.map(word => (
                          <tr key={word[0]}>
                            <td>{word[0]}</td>
                            <td className="col-md-2">
                              {(word[1] * 100).toFixed(1)} %
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* graph start */}
            <section className="my-5">
              <div className="row text-center">
                <div className="col-sm-12">
                  <h3>Fraction of posts per blog mentioning "{word}"</h3>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-sm-12">
                  <div className="d-flex justify-content-center c-graph">
                    <BarChart
                      width={600}
                      height={300}
                      data={wordData.blog}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        interval={0}
                        textAnchor="end"
                        tick={{ angle: -45, fontSize: 12 }}
                      />
                      <YAxis tick={{ fontSize: 12 }} unit="%" />
                      <Tooltip />
                      <Bar
                        dataKey="perGr"
                        name="Percent"
                        fill="#4BC0C5"
                        unit="%"
                      />
                    </BarChart>
                  </div>
                </div>
              </div>
            </section>
            {/* graph end */}
            {/* graph 2 start */}
            <section className="my-5">
              <section className="row text-center">
                <div className="col-sm-12">
                  <h3>Total count of "{word}" per blog</h3>
                </div>
              </section>
              <div className="row text-center">
                <div className="col-sm-12">
                  <div className="d-flex justify-content-center c-graph">
                    <BarChart
                      width={600}
                      height={300}
                      data={wordData.blog}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        interval={0}
                        textAnchor="end"
                        tick={{ angle: -45, fontSize: 12 }}
                      />
                      <YAxis tick={{ fontSize: 12 }} />
                      <Tooltip />
                      <Bar dataKey="sumGr" name="Word count" fill="#F17C59" />
                    </BarChart>
                  </div>
                </div>
              </div>
            </section>
            {/* graph 2 end */}
          </>
        ) : (
          <div className="text-center py-5">
            {this.state.hasFailed ? <>loading failed</> : <>loading...</>}
          </div>
        )}
      </section>
    )
  }
}
