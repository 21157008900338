import React from 'react'
import Plot from '$components/PlotlyWrapper';

export default () => {
  return (
    <div
      className="container my-5 d-flex justify-content-center"
      style={{ height: '400px' }}
    >
      <Plot
        data={[
          {
            type: 'scatter',
            x: [
              '2017-03-01',
              '2016-09-01',
              '2016-10-01',
              '2017-01-01',
              '2016-03-01',
              '2017-02-01',
              '2017-06-01',
              '2016-06-01',
              '2016-12-01',
              '2016-07-01',
              '2017-04-01',
              '2016-01-01',
              '2016-11-01',
              '2016-05-01',
              '2016-02-01',
              '2017-05-01',
              '2016-08-01',
              '2016-04-01',
            ],
            y: [
              32.0,
              28.0,
              28.0,
              27.0,
              24.0,
              24.0,
              24.0,
              23.0,
              21.0,
              20.0,
              20.0,
              19.0,
              19.0,
              18.0,
              18.0,
              17.0,
              15.0,
              11.0,
            ],
            mode: 'markers',
            name: 'FinTech',
            opacity: 0.6,
            marker: {
              color: 'hsl(0.0,60%,60%)',
              size: [
                47.3465,
                29.289225,
                24.5,
                64.65195,
                55.0,
                26.9523375,
                52.6984,
                34.0,
                30.0,
                32.52855,
                24.43065,
                33.07536,
                30.0,
                70,
                29.9330875,
                57.25,
                32.10025,
                20.0,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 4.7M',
              'Median: 2.9M',
              'Median: 2.4M',
              'Median: 6.5M',
              'Median: 5.5M',
              'Median: 2.7M',
              'Median: 5.3M',
              'Median: 3.4M',
              'Median: 3.0M',
              'Median: 3.3M',
              'Median: 2.4M',
              'Median: 3.3M',
              'Median: 3.0M',
              'Median: 8.4M',
              'Median: 3.0M',
              'Median: 5.7M',
              'Median: 3.2M',
              'Median: 2.0M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-01-01',
              '2017-04-01',
              '2016-11-01',
              '2016-07-01',
              '2017-06-01',
              '2016-01-01',
              '2017-03-01',
              '2016-10-01',
              '2016-06-01',
              '2016-02-01',
              '2016-04-01',
              '2016-05-01',
              '2016-12-01',
              '2016-09-01',
              '2016-03-01',
              '2017-05-01',
              '2016-08-01',
              '2017-02-01',
            ],
            y: [
              22.0,
              20.0,
              19.0,
              19.0,
              18.0,
              16.0,
              15.0,
              15.0,
              15.0,
              15.0,
              14.0,
              14.0,
              13.0,
              13.0,
              12.0,
              12.0,
              11.0,
              7.0,
            ],
            mode: 'markers',
            name: 'HealthTech',
            opacity: 0.6,
            marker: {
              color: 'hsl(51.4285714286,60%,60%)',
              size: [
                26.0,
                20.44,
                24.0,
                60.0,
                47.6984,
                23.0,
                20.974,
                20.0,
                21.0,
                58.45,
                26.134656,
                27.1664,
                25.58806,
                29.0,
                65.7385,
                39.44925,
                30.0,
                50.0,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 2.6M',
              'Median: 2.0M',
              'Median: 2.4M',
              'Median: 6.0M',
              'Median: 4.8M',
              'Median: 2.3M',
              'Median: 2.1M',
              'Median: 2.0M',
              'Median: 2.1M',
              'Median: 5.8M',
              'Median: 2.6M',
              'Median: 2.7M',
              'Median: 2.6M',
              'Median: 2.9M',
              'Median: 6.6M',
              'Median: 3.9M',
              'Median: 3.0M',
              'Median: 5.0M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-09-01',
              '2016-02-01',
              '2016-11-01',
              '2016-10-01',
              '2016-05-01',
              '2016-04-01',
              '2017-02-01',
              '2016-06-01',
              '2016-07-01',
              '2017-01-01',
              '2016-01-01',
              '2017-06-01',
              '2017-03-01',
              '2016-03-01',
              '2016-12-01',
              '2017-04-01',
              '2016-08-01',
              '2017-05-01',
            ],
            y: [
              22.0,
              18.0,
              16.0,
              14.0,
              14.0,
              14.0,
              11.0,
              11.0,
              10.0,
              9.0,
              8.0,
              8.0,
              8.0,
              7.0,
              7.0,
              6.0,
              6.0,
              5.0,
            ],
            mode: 'markers',
            name: 'MadTech',
            opacity: 0.6,
            marker: {
              color: 'hsl(102.857142857,60%,60%)',
              size: [
                15.14162,
                45.872465,
                32.437525,
                18.68234,
                27.5,
                17.9304375,
                30.0,
                30.0,
                11.50359,
                24.4712,
                37.86117,
                15.715125,
                37.5,
                32.2365,
                20.0,
                34.5886,
                29.18884,
                55.0866,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 1.5M',
              'Median: 4.6M',
              'Median: 3.2M',
              'Median: 1.9M',
              'Median: 2.8M',
              'Median: 1.8M',
              'Median: 3.0M',
              'Median: 3.0M',
              'Median: 1.2M',
              'Median: 2.4M',
              'Median: 3.8M',
              'Median: 1.6M',
              'Median: 3.8M',
              'Median: 3.2M',
              'Median: 2.0M',
              'Median: 3.5M',
              'Median: 2.9M',
              'Median: 5.5M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-03-01',
              '2016-09-01',
              '2016-06-01',
              '2016-10-01',
              '2016-07-01',
              '2017-02-01',
              '2016-12-01',
              '2017-01-01',
              '2016-11-01',
              '2017-04-01',
              '2017-05-01',
              '2017-06-01',
              '2016-04-01',
              '2016-08-01',
              '2016-05-01',
              '2017-03-01',
              '2016-01-01',
              '2016-02-01',
            ],
            y: [
              18.0,
              14.0,
              12.0,
              10.0,
              10.0,
              9.0,
              8.0,
              8.0,
              6.0,
              6.0,
              6.0,
              5.0,
              5.0,
              5.0,
              5.0,
              5.0,
              5.0,
              2.0,
            ],
            mode: 'markers',
            name: 'eCommerce',
            opacity: 0.6,
            marker: {
              color: 'hsl(154.285714286,60%,60%)',
              size: [
                14.0,
                21.676647999999997,
                45.0,
                22.725,
                31.5,
                30.0,
                16.48804,
                40.325975,
                10.394,
                28.1587825,
                17.847875,
                70,
                26.1408,
                70,
                27.23,
                20.0,
                15.61892,
                48.85045,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 1.4M',
              'Median: 2.2M',
              'Median: 4.5M',
              'Median: 2.3M',
              'Median: 3.2M',
              'Median: 3.0M',
              'Median: 1.6M',
              'Median: 4.0M',
              'Median: 1.0M',
              'Median: 2.8M',
              'Median: 1.8M',
              'Median: 10.0M',
              'Median: 2.6M',
              'Median: 9.7M',
              'Median: 2.7M',
              'Median: 2.0M',
              'Median: 1.6M',
              'Median: 4.9M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-06-01',
              '2016-02-01',
              '2017-03-01',
              '2016-09-01',
              '2016-04-01',
              '2017-06-01',
              '2017-04-01',
              '2016-01-01',
              '2016-10-01',
              '2016-05-01',
              '2017-05-01',
              '2017-01-01',
              '2016-07-01',
              '2016-08-01',
              '2016-12-01',
              '2017-02-01',
              '2016-11-01',
              '2016-03-01',
            ],
            y: [
              15.0,
              10.0,
              10.0,
              9.0,
              8.0,
              8.0,
              6.0,
              6.0,
              6.0,
              5.0,
              5.0,
              4.0,
              4.0,
              4.0,
              4.0,
              4.0,
              3.0,
              2.0,
            ],
            mode: 'markers',
            name: 'PropTech',
            opacity: 0.6,
            marker: {
              color: 'hsl(205.714285714,60%,60%)',
              size: [
                20.0,
                12.10552,
                26.031,
                20.0,
                16.35,
                21.51525,
                13.928895,
                17.0,
                16.9712025,
                25.3774,
                70,
                17.07365,
                35.1857,
                32.44175,
                43.0,
                18.273535,
                25.0,
                70,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 2.0M',
              'Median: 1.2M',
              'Median: 2.6M',
              'Median: 2.0M',
              'Median: 1.6M',
              'Median: 2.2M',
              'Median: 1.4M',
              'Median: 1.7M',
              'Median: 1.7M',
              'Median: 2.5M',
              'Median: 10.8M',
              'Median: 1.7M',
              'Median: 3.5M',
              'Median: 3.2M',
              'Median: 4.3M',
              'Median: 1.8M',
              'Median: 2.5M',
              'Median: 10.6M',
            ],
          },
        ]}
        layout={{
          width: '100%',
          height: '100%',
          title: '# Investments per Industry (Size is Median of investment)',
          xaxis: { title: 'Date' },
          yaxis: { title: '# Investments' },
        }}
      />
    </div>
  )
}
