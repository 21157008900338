import React from 'react'
import Plot from '$components/PlotlyWrapper';

export default () => {
  return (
    <div
      className="container my-5 d-flex justify-content-center"
      style={{ height: '400px' }}
    >
      <Plot
        data={[
          {
            type: 'scatter',
            x: [
              '2017-03-01',
              '2016-10-01',
              '2017-04-01',
              '2017-01-01',
              '2017-05-01',
              '2016-11-01',
              '2016-06-01',
              '2017-06-01',
              '2016-03-01',
              '2016-01-01',
              '2017-02-01',
              '2016-09-01',
              '2016-08-01',
              '2016-07-01',
              '2016-05-01',
              '2016-02-01',
              '2016-04-01',
              '2016-12-01',
            ],
            y: [
              24.0,
              22.0,
              20.0,
              20.0,
              20.0,
              18.0,
              18.0,
              15.0,
              14.0,
              12.0,
              12.0,
              11.0,
              11.0,
              11.0,
              9.0,
              9.0,
              8.0,
              8.0,
            ],
            mode: 'markers',
            name: 'Artificial Intelligence',
            opacity: 0.6,
            marker: {
              color: 'hsl(0.0,60%,60%)',
              size: [
                31.91098,
                30.568,
                22.1289,
                20.0,
                30.369,
                34.46,
                19.4159,
                22.5,
                29.3419,
                18.6876,
                28.8879,
                18.54208,
                32.99608,
                18.088794,
                29.13632,
                14.3,
                51.48254,
                12.5,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 3.2M',
              'Median: 3.1M',
              'Median: 2.2M',
              'Median: 2.0M',
              'Median: 3.0M',
              'Median: 3.4M',
              'Median: 1.9M',
              'Median: 2.2M',
              'Median: 2.9M',
              'Median: 1.9M',
              'Median: 2.9M',
              'Median: 1.9M',
              'Median: 3.3M',
              'Median: 1.8M',
              'Median: 2.9M',
              'Median: 1.4M',
              'Median: 5.1M',
              'Median: 1.2M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-01-01',
              '2016-03-01',
              '2016-06-01',
              '2016-10-01',
              '2017-02-01',
              '2016-04-01',
              '2016-02-01',
              '2016-05-01',
              '2017-03-01',
              '2017-04-01',
              '2016-09-01',
              '2016-11-01',
              '2017-01-01',
              '2017-06-01',
              '2016-07-01',
              '2016-12-01',
              '2017-05-01',
              '2016-08-01',
            ],
            y: [
              23.0,
              18.0,
              18.0,
              18.0,
              18.0,
              16.0,
              15.0,
              15.0,
              14.0,
              12.0,
              10.0,
              10.0,
              10.0,
              10.0,
              8.0,
              7.0,
              5.0,
              5.0,
            ],
            mode: 'markers',
            name: 'SaaS',
            opacity: 0.6,
            marker: {
              color: 'hsl(51.4285714286,60%,60%)',
              size: [
                41.3442,
                24.57328,
                28.37385,
                26.225,
                28.12625,
                20.0,
                17.768604,
                12.0,
                23.17256,
                48.5,
                25.08645,
                48.216775,
                20.0,
                20.0,
                16.04424,
                30.0,
                70,
                40.0,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 4.1M',
              'Median: 2.5M',
              'Median: 2.8M',
              'Median: 2.6M',
              'Median: 2.8M',
              'Median: 2.0M',
              'Median: 1.8M',
              'Median: 1.2M',
              'Median: 2.3M',
              'Median: 4.8M',
              'Median: 2.5M',
              'Median: 4.8M',
              'Median: 2.0M',
              'Median: 2.0M',
              'Median: 1.6M',
              'Median: 3.0M',
              'Median: 8.0M',
              'Median: 4.0M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-06-01',
              '2017-03-01',
              '2016-05-01',
              '2017-01-01',
              '2016-12-01',
              '2017-04-01',
              '2017-05-01',
              '2016-06-01',
              '2016-03-01',
              '2016-04-01',
              '2016-01-01',
              '2016-09-01',
              '2016-10-01',
              '2016-02-01',
              '2017-02-01',
              '2016-07-01',
              '2016-11-01',
              '2016-08-01',
            ],
            y: [
              23.0,
              13.0,
              12.0,
              12.0,
              10.0,
              10.0,
              10.0,
              9.0,
              9.0,
              9.0,
              8.0,
              8.0,
              7.0,
              7.0,
              6.0,
              6.0,
              6.0,
              3.0,
            ],
            mode: 'markers',
            name: 'App',
            opacity: 0.6,
            marker: {
              color: 'hsl(102.857142857,60%,60%)',
              size: [
                70,
                20.88,
                32.061,
                23.33665,
                37.96856,
                15.33,
                39.12925,
                19.89284,
                19.3419,
                14.0,
                16.0783,
                19.178875,
                11.3465,
                30.0,
                21.8381,
                17.107365,
                20.48671,
                30.0,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 8.3M',
              'Median: 2.1M',
              'Median: 3.2M',
              'Median: 2.3M',
              'Median: 3.8M',
              'Median: 1.5M',
              'Median: 3.9M',
              'Median: 2.0M',
              'Median: 1.9M',
              'Median: 1.4M',
              'Median: 1.6M',
              'Median: 1.9M',
              'Median: 1.1M',
              'Median: 3.0M',
              'Median: 2.2M',
              'Median: 1.7M',
              'Median: 2.0M',
              'Median: 3.0M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-02-01',
              '2016-02-01',
              '2016-09-01',
              '2016-07-01',
              '2016-06-01',
              '2017-04-01',
              '2017-01-01',
              '2016-11-01',
              '2016-04-01',
              '2016-03-01',
              '2016-05-01',
              '2017-05-01',
              '2017-03-01',
              '2016-10-01',
              '2016-08-01',
              '2016-12-01',
              '2017-06-01',
              '2016-01-01',
            ],
            y: [
              20.0,
              16.0,
              16.0,
              15.0,
              15.0,
              15.0,
              14.0,
              14.0,
              13.0,
              13.0,
              12.0,
              12.0,
              10.0,
              9.0,
              7.0,
              7.0,
              7.0,
              6.0,
            ],
            mode: 'markers',
            name: 'Data Analytics',
            opacity: 0.6,
            marker: {
              color: 'hsl(154.285714286,60%,60%)',
              size: [
                29.13915,
                34.195315,
                25.72006,
                10.0,
                30.0,
                29.1772,
                50.0,
                18.10387,
                12.6887,
                32.2365,
                55.5,
                47.56425,
                41.5,
                39.90456,
                25.3816,
                12.00017,
                30.0,
                36.5,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 2.9M',
              'Median: 3.4M',
              'Median: 2.6M',
              'Median: 1.0M',
              'Median: 3.0M',
              'Median: 2.9M',
              'Median: 5.0M',
              'Median: 1.8M',
              'Median: 1.3M',
              'Median: 3.2M',
              'Median: 5.6M',
              'Median: 4.8M',
              'Median: 4.2M',
              'Median: 4.0M',
              'Median: 2.5M',
              'Median: 1.2M',
              'Median: 3.0M',
              'Median: 3.6M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-07-01',
              '2016-06-01',
              '2017-01-01',
              '2017-03-01',
              '2016-12-01',
              '2016-09-01',
              '2016-05-01',
              '2016-02-01',
              '2016-08-01',
              '2016-11-01',
              '2016-04-01',
              '2017-04-01',
              '2016-03-01',
              '2016-01-01',
              '2017-05-01',
              '2016-10-01',
              '2017-06-01',
              '2017-02-01',
            ],
            y: [
              19.0,
              18.0,
              17.0,
              15.0,
              15.0,
              15.0,
              14.0,
              13.0,
              13.0,
              13.0,
              12.0,
              10.0,
              10.0,
              9.0,
              9.0,
              9.0,
              7.0,
              4.0,
            ],
            mode: 'markers',
            name: 'Connected Hardware',
            opacity: 0.6,
            marker: {
              color: 'hsl(205.714285714,60%,60%)',
              size: [
                64.0,
                23.45361,
                25.0,
                24.0,
                35.0,
                22.2895,
                34.8166,
                45.0653,
                30.0,
                31.0,
                70,
                31.020635,
                51.33605,
                18.3752,
                60.0,
                27.2235,
                60.0,
                70,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 6.4M',
              'Median: 2.3M',
              'Median: 2.5M',
              'Median: 2.4M',
              'Median: 3.5M',
              'Median: 2.2M',
              'Median: 3.5M',
              'Median: 4.5M',
              'Median: 3.0M',
              'Median: 3.1M',
              'Median: 12.2M',
              'Median: 3.1M',
              'Median: 5.1M',
              'Median: 1.8M',
              'Median: 6.0M',
              'Median: 2.7M',
              'Median: 6.0M',
              'Median: 12.9M',
            ],
          },
        ]}
        layout={{
          width: '100%',
          height: '100%',
          title: '# Investments per Technology (Size is Median of investment)',
          xaxis: { title: 'Date' },
          yaxis: { title: '# Investments' },
        }}
      />
    </div>
  )
}
