import React from 'react'
import Plot from '$components/PlotlyWrapper';

export default () => {
  return (
    <div
      className="container my-5 d-flex justify-content-center"
      style={{ height: '400px' }}
    >
      <Plot
        data={[
          {
            type: 'bar',
            x: [
              'London',
              'Paris',
              'Berlin',
              'Stockholm',
              'Barcelona',
              'Dublin',
              'Munich',
              'Amsterdam',
              'Copenhagen',
              'Milan',
              'Helsinki',
              'Cambridge',
              'Madrid',
              'Oslo',
              'Zurich',
              'Gothenburg',
              'Brussels',
              'Ghent',
              'Lausanne',
              'Hamburg',
              '-OTHERS-',
            ],
            y: [
              4879974633.97748,
              2952620156.5,
              2644760201.4,
              2330926931.35,
              602632619.1643523,
              478049368.0,
              435799205.0,
              558016736.0,
              439913132.4,
              134418576.0,
              236621096.94,
              286756391.5248,
              372853116.0,
              167818457.60000002,
              553893615.0,
              149261712.0,
              137204954.63,
              112697558.0,
              219915291.7,
              455893294.0,
              8460852710.516052,
            ],
            marker: { color: '#3CBEC5' },
          },
        ]}
        layout={{
          width: '100%',
          height: '100%',
          title: 'Sum of Investment per City',
        }}
      />
    </div>
  )
}
