import React from 'react'
import Plot from '$components/PlotlyWrapper';

const StartUpBarChart = () => {
  return (
    <div
      className="container my-5 d-flex justify-content-center"
      style={{ height: '400px' }}
    >
      <Plot
        data={[
          {
            x: [
              40426200.0,
              32000000.0,
              31500000.0,
              29000000.0,
              22969000.0,
              13475400.0,
              10000000.0,
              10000000.0,
              8000000.0,
              8881900.0,
              6500000.0,
              6300000.0,
              7533832.0,
              176401920.0,
              64313200.0,
              45938000.0,
              16542875.0,
              11943880.0,
              2756280.0,
              1837520.0,
              1470016.0,
              36750400.0,
              4593800.0,
              16537680.0,
              10190411.0,
              6615072.0,
              7940580.000000001,
              4632005.0,
              4367319.0,
              1561892.0,
              1378140.0,
              45938000.0,
              27562800.0,
              13781400.0,
              5971940.0,
              20212720.0,
              2296900.0,
              1470016.0,
              1654287.5,
              300000000.0,
              100000000.0,
              75000000.0,
              31067050.0,
              31000000.0,
              20900000.0,
              18000000.0,
              15400000.0,
              15000000.0,
              14000000.0,
              13000000.0,
              13000000.0,
              12000000.0,
              12000000.0,
              10000000.0,
              9200000.0,
              9187600.0,
              6615072.0,
              6410000.0,
              6100000.0,
              5300000.0,
              5300000.0,
              5200000.0,
              5000000.0,
              2200000.0,
              22969000.0,
              2000000.0,
              4000000.0,
              2416338.8,
              3300000.0,
              8268840.0,
              5512560.0,
              9187600.0,
              1139262.4,
              1000000.0,
              4134420.0,
              2300000.0,
              2700000.0,
              1102512.0,
              1929396.0,
              55000000.0,
              4272234.0,
              36750400.0,
              4134420.0,
              1400000.0,
              1194388.0,
              2000000.0,
              1200000.0,
              2756280.0,
              1800000.0,
              2000000.0,
              4134420.0,
              1500000.0,
              11025120.0,
              1837520.0,
              3675040.0,
              6890700.0,
              2269337.2,
              9830732.0,
              1010636.0,
              9187600.0,
              4000000.0,
              1900000.0,
              2000000.0,
              1000000.0,
              10000000.0,
              1500000.0,
              1000000.0,
              2500000.0,
              1632000.0,
              5000000.0,
              2757686.0,
              1000000.0,
              7105520.0,
              6000000.0,
              15800000.0,
              8881900.0,
              7549615.0,
              6000000.0,
              5329140.0,
              6000000.0,
              5000000.0,
              4263312.0,
              3862740.0,
              5500000.0,
              2220475.0,
              3218950.0,
              5000000.0,
              2100000.0,
              2000000.0,
              5300000.0,
              2124507.0,
              1600000.0,
              5000000.0,
              1000000.0,
              5000000.0,
              888190.0,
              1158822.0,
              4500000.0,
              5000000.0,
              4506530.0,
              792028.0,
              53291400.0,
              39968550.0,
              19540180.0,
              4200000.0,
              12434660.0,
              4200000.0,
              5000000.0,
              6000000.0,
              6000000.0,
              5062683.0,
              4440950.0,
              4100000.0,
              5922868.0,
              2188886.0,
              834351.84,
              1287580.0,
              3862740.0,
              3100000.0,
              2664570.0,
              4000000.0,
              2200000.0,
              1776380.0,
              4000000.0,
              1416338.0,
              1300000.0,
              1154647.0,
              4000000.0,
              4000000.0,
              3700000.0,
              5000000.0,
              1421104.0,
              88819000.0,
              6661425.0,
              6217330.0,
              6000000.0,
              7725480.0,
              5845000.0,
              3500000.0,
              4440950.0,
              2886617.5,
              3000000.0,
              2400000.0,
              5329140.0,
              2000000.0,
              5053180.0,
              4000000.0,
              2000000.0,
              1500000.0,
              1287580.0,
              3000000.0,
              800000.0,
              1000000.0,
              12000000.0,
              35527600.0,
              5329140.0,
              6000000.0,
              3550000.0,
              4174493.0,
              3000000.0,
              2664570.0,
              3000000.0,
              2000000.0,
              2000000.0,
              1776380.0,
              3000000.0,
              1287875.5,
              1243466.0,
              3000000.0,
              1300000.0,
              2600000.0,
              1200000.0,
              2526590.0,
              4051603.6,
              3000000.0,
              3215660.0,
              850000.0,
              2000000.0,
              2000000.0,
              1500000.0,
              444095000.0,
              48511440.0,
              8881900.0,
              7186880.0,
              2500000.0,
              2500000.0,
              4000000.0,
              1700000.0,
              2578920.0,
              1796720.0,
              1167868.0,
              2300000.0,
              1500000.0,
              2300000.0,
              902622.0,
              1500000.0,
              3593440.0,
              10000000.0,
              29645880.0,
              58025700.0,
              20000000.0,
              20502414.0,
              2211850.0,
              2200000.0,
              1200000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              4491800.0,
              902622.0,
              2695080.0,
              2578920.0,
              2000000.0,
              2000000.0,
              1572130.0,
              1500000.0,
              2000000.0,
              1900000.0,
              2000000.0,
              1000000.0,
              898360.0,
              898360.0,
              1000000.0,
              2000000.0,
              33000000.0,
              142478700.0,
              21920820.0,
              4491800.0,
              1000000.0,
              4267210.0,
              3600000.0,
              3000000.0,
              3000000.0,
              2000000.0,
              2100000.0,
              2100000.0,
              1500000.0,
              1289460.0,
              1000000.0,
              1000000.0,
              898360.0,
              1000000.0,
              1000000.0,
              13475400.0,
              965000.0,
              11678680.0,
              850000.0,
              8000000.0,
              7186880.0,
              2000000.0,
              2000000.0,
              4491800.0,
              3200000.0,
              3868380.0,
              2578920.0,
              2200000.0,
              1886556.0,
              2192082.0,
              1934190.0,
              1500000.0,
              1805244.0,
              1300000.0,
              2000000.0,
              1078032.0,
              1078032.0,
              9792124.0,
              1418406.0,
              1000000.0,
              979212.4,
              898360000.0,
              12577040.0,
              2000000.0,
              2000000.0,
              10000000.0,
              7000000.0,
              800000.0,
              4491800.0,
              4000000.0,
              3200000.0,
              3223650.0,
              2335736.0,
              1000000.0,
              1000000.0,
              773860.0,
              2000000.0,
              888190.0,
              888190.0,
              800000.0,
              1000000.0,
              3000000.0,
              1800000.0,
              1500000.0,
              1500000.0,
              1500000.0,
              1500000.0,
              1500000.0,
              1500000.0,
              1400000.0,
              1400000.0,
              1300000.0,
              1300000.0,
              1300000.0,
              1300000.0,
              1300000.0,
              1250000.0,
              1200000.0,
              1200000.0,
              1200000.0,
              898360.0,
              2756280.0,
              1200000.0,
              33000000.0,
              1200000.0,
              16954024.0,
              6584175.0,
              7000000.0,
              1200000.0,
              4500000.0,
              1200000.0,
              3700000.0,
              1700000.0,
              1100000.0,
              1296000.0,
              1296000.0,
              1250000.0,
              1237520.0,
              1237520.0,
              1000000.0,
              877890.0,
              877890.0,
              800000.0,
              750000.0,
              750000.0,
              1000000.0,
              3250000.0,
              750000.0,
              1000000.0,
              9000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              3700000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              35505200.0,
              1100000.0,
              22190750.0,
              25000000.0,
              22190750.0,
              17752600.0,
              10000000.0,
              8432485.0,
              6657225.0,
              6500000.0,
              5547687.5,
              4438150.0,
              1000000.0,
              3639283.0,
              3000000.0,
              1000000.0,
              1000000.0,
              3769080.0,
              3268200.0,
              1947358.0,
              1400000.0,
              1000000.0,
              1256360.0,
              1000000.0,
              887630.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              3000000.0,
              106168800.0,
              4423700.0,
              4500000.0,
              2654220.0,
              2477272.0,
              1000000.0,
              980000.0,
              1400000.0,
              990908.7999999999,
              1268870.0,
              1000000.0,
              831655.6,
              780000.0,
              762440.0,
              2000000.0,
              6000000.0,
              63701280.0,
              900000.0,
              20349020.0,
              890000.0,
              5308440.0,
              4954544.0,
              4574640.0,
              3538960.0,
              2283966.0,
              2614080.0,
              2500000.0,
              2000000.0,
              800000.0,
              750000.0,
              2537740.0,
              1500000.0,
              1586087.5,
              750000.0,
              1089200.0,
              884740.0,
              800000.0,
              762440.0,
              750000.0,
              1000000.0,
              97321400.0,
              23887980.0,
              13271100.0,
              10616880.0,
              5500000.0,
              4866070.0,
              4060384.0,
              2940840.0,
              2600000.0,
              1500000.0,
              1000000.0,
              750000.0,
              800000.0,
              1000000.0,
              850000.0,
              88474000.0,
              31721750.0,
              30579767.0,
              20000000.0,
              20000000.0,
              17694800.0,
              18000000.0,
              13271100.0,
              13271100.0,
              15226440.0,
              9953325.0,
              9732140.0,
              10150960.0,
              5000000.0,
              3538960.0,
              3096590.0,
              2933280.0,
              2918401.0,
              2287320.0,
              1200000.0,
              1100000.0,
              1043460.0,
              1268870.0,
              1000000.0,
              925820.0,
              871360.0,
              879984.0,
              800000.0,
              761322.0,
              40000000.0,
              2000000.0,
              1100000.0,
              3300000.0,
              5500000.0,
              2500000.0,
              1200000.0,
              1200000.0,
              3209724.0,
              2000000.0,
              1200000.0,
              1000000.0,
              800000.0,
              1000000.0,
              1000000.0,
              1300000.0,
              1000000.0,
              750000.0,
              1000000.0,
              1000000.0,
              2017207.2,
              8000000.0,
              44237000.0,
              26542200.0,
              10000000.0,
              8847400.0,
              8847400.0,
              9258200.0,
              7077920.0,
              6500000.0,
              4423700.0,
              3981330.0,
              4100000.0,
              4030040.0,
              2723000.0,
              2178400.0,
              2537740.0,
              1821020.0,
              1800000.0,
              1750000.0,
              1200000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              884740.0,
              871360.0,
              812076.7999999999,
              5000000.0,
              1399927.31,
              10430000.0,
              1000000.0,
              23003240.0,
              19906650.0,
              15925320.0,
              11501620.0,
              15040580.0,
              8000000.0,
              7000000.0,
              5750810.0,
              5308440.0,
              4423700.0,
              3812200.0,
              3600000.0,
              2913632.0,
              2723000.0,
              2500000.0,
              2211850.0,
              2537740.0,
              2000000.0,
              1742720.0,
              1500000.0,
              1524880.0,
              1522644.0,
              1200000.0,
              1268870.0,
              850000.0,
              800000.0,
              762440.0,
              888209.0,
              2500000.0,
              800000.0,
              58120370.0,
              23500000.0,
              14354599.0,
              10699080.0,
              8915900.0,
              8024310.0,
              8024310.0,
              5349540.0,
              5053560.0,
              4000000.0,
              4000000.0,
              3790170.0,
              3790170.0,
              2500000.0,
              2100000.0,
              2020000.0,
              2000000.0,
              1500000.0,
              1337385.0,
              1750000.0,
              891590.0,
              810000.0,
              800000.0,
              800000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              66869250.0,
              24964520.0,
              24518725.0,
              17831800.0,
              8915900.0,
              9042200.0,
              6686925.0,
              6241130.0,
              5973653.0,
              5349540.0,
              5500000.0,
              4000000.0,
              4000000.0,
              3400000.0,
              2674770.0,
              1827759.5,
              2200000.0,
              1988245.7,
              1989284.0,
              2770619.0,
              1617450.0,
              1739691.0,
              1000000.0,
              1000000.0,
              891590.0,
              1500000.0,
              34221960.7,
              62411300.0,
              37446780.0,
              13730486.0,
              11000000.0,
              8024310.0,
              6000000.0,
              6000000.0,
              3566360.0,
              3400000.0,
              3000000.0,
              2712660.0,
              2600000.0,
              2000000.0,
              2000000.0,
              2000000.0,
              1783180.0,
              1700000.0,
              1491963.0,
              1250000.0,
              1078300.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              753866.1,
              10000000.0,
              5000000.0,
              2000000.0,
              2156600.0,
              800000.0,
              1200000.0,
              35227200.0,
              26420400.0,
              14000000.0,
              15411900.0,
              9687480.0,
              20000000.0,
              8500000.0,
              5284080.0,
              5500000.0,
              5000000.0,
              4600000.0,
              4000000.0,
              4000000.0,
              3000000.0,
              3000000.0,
              2201700.0,
              2147400.0,
              1986345.0,
              2340000.0,
              1957095.0,
              1957095.0,
              1500000.0,
              1500000.0,
              1300000.0,
              1144884.0,
              1630912.5,
              1200000.0,
              1000000.0,
              2034902.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1200000.0,
              765000.0,
              782838.0,
              3500000.0,
              1000000.0,
              17694800.0,
              2549986.11,
              2650000.0,
              2100000.0,
              1000000.0,
              31663456.0,
              14000000.0,
              8095770.0,
              7646005.0,
              5667039.0,
              5397180.0,
              5487133.0,
              3500000.0,
              3500000.0,
              3058402.0,
              2698590.0,
              2113895.5,
              1300000.0,
              1200000.0,
              1200000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              17288000.0,
              3000000.0,
              2410740.4,
              2302796.8,
              1439248.0,
              1079436.0,
              5000000.0,
              3000000.0,
              1450000.0,
              1000000.0,
              1000000.0,
              750000.0,
              26000000.0,
              7000000.0,
              3000000.0,
              3000000.0,
              2500000.0,
              1400000.0,
              1200000.0,
              1000000.0,
              1000000.0,
              900000.0,
              2000000.0,
              2500000.0,
              1000000.0,
              58655350.0,
              44217110.0,
              18719224.5,
              12500000.0,
              12000000.0,
              9294617.0,
              9023900.0,
              9800000.0,
              8764275.0,
              6400000.0,
              7245134.000000001,
              5544240.0,
              6193421.000000001,
              3973372.0,
              4000000.0,
              3141736.0,
              3505710.0,
              2453997.0,
              2337140.0,
              2000000.0,
              1700000.0,
              1108848.0,
              1000000.0,
              1000000.0,
              1054900.0,
              1000000.0,
              1000000.0,
              924040.0,
              924040.0,
              924040.0,
              1000000.0,
              59593500.0,
              9236992.5,
              22498500.0,
              13000000.0,
              8999400.0,
              8200000.0,
              8343090.0,
              9236992.5,
              7747155.0,
              6000000.0,
              4274715.0,
              3575610.0,
              3000000.0,
              3000000.0,
              2429838.0,
              2100000.0,
              2000000.0,
              1668618.0,
              1500000.0,
              1400000.0,
              1191870.0,
              1191870.0,
              1191870.0,
              1000000.0,
              1000000.0,
              1000000.0,
              830000.0,
              5000000.0,
              750000.0,
              3952921.32,
              1140895.0,
              45370500.0,
              22685250.0,
              18420423.0,
              15000000.0,
              9074100.0,
              7794995.0,
              5500000.0,
              5300000.0,
              5996150.0,
              3000000.0,
              3000000.0,
              3692150.0,
              2600000.0,
              2268525.0,
              2000000.0,
              1700000.0,
              1200000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              907410.0,
              839461.0,
              3000000.0,
              1000000.0,
              1344200.0,
              5000000.0,
              4000000.0,
              3300000.0,
              2000000.0,
              1000000.0,
              4700000.0,
              2000000.0,
              1000000.0,
              1000000.0,
              12000000.0,
              1000000.0,
              3800000.0,
              13536750.0,
              10829400.0,
              5756300.0,
              3600000.0,
              3000000.0,
              2346370.0,
              1804900.0,
              1804900.0,
              1300000.0,
              837280.0,
              2000000.0,
              1000000.0,
              1000000.0,
              1084276.88,
              8847400.0,
              246859250.0,
              84829815.0,
              42190490.0,
              13200000.0,
              13000000.0,
              11310642.0,
              10772040.0,
              10000000.0,
              6283690.0,
              5900000.0,
              4488350.0,
              3565500.0,
              3500000.0,
              3000000.0,
              2000000.0,
              1615806.0,
              1500000.0,
              1346505.0,
              1300000.0,
              1200000.0,
              1307350.0,
              3000000.0,
              1579899.2,
              3000000.0,
              6317150.0,
              1526400.0,
              1837520.0,
              47590500.0,
              24000000.0,
              20000000.0,
              9723230.0,
              8839300.0,
              1075440.0,
              5303580.0,
              5000000.0,
              3535720.0,
              2651790.0,
              3400000.0,
              2538160.0,
              1200000.0,
              1325895.0,
              1360000.0,
              1434060.4,
              1000000.0,
              2000000.0,
              1000000.0,
              1000000.0,
              962280.0,
              58025700.0,
              1700000.0,
              3535720.0,
              19435460.0,
              14000000.0,
              7420812.0,
              6946320.000000001,
              6322200.0,
              6100000.0,
              6000000.0,
              4858865.0,
              5144945.4,
              934200.8754,
              1218307.5972,
              1082089.0428,
              755889.4296,
              1776860.4,
              4000000.0,
              2300000.0,
              2200000.0,
              1766860.0,
              835861.44,
              2000000.0,
              2000000.0,
              2700000.0,
              22255080.0,
              9956220.0,
              2110200.0,
              13299750.0,
              7385700.0,
              7300000.0,
              5319900.0,
              5000000.0,
              3513960.0,
              3210025.0,
              2216625.0,
              1750000.0,
              1684635.0,
              1471839.0,
              1500000.0,
              1300000.0,
              1055100.0,
              1688160.0,
              753270.0,
              800000.0,
              2000000.0,
              1783180.0,
              886650.0,
              24840270.0,
              20154150.0,
              20154000.0,
              19517355.0,
              17914800.0,
              13500000.0,
              4000000.0,
              3075462.0,
              3000000.0,
              2239350.0,
              1656018.0,
              1567950.0,
              1500000.0,
              1366725.0,
              1100000.0,
              1045300.0,
              1000000.0,
              1600000.0,
              1000000.0,
              836240.0,
              806166.0,
              1000000.0,
              1400000.0,
              1000000.0,
              2000000.0,
              2600000.0,
              177582000.0,
              20000000.0,
              13318650.0,
              10000000.0,
              12000000.0,
              9145473.0,
              8879100.0,
              8879100.0,
              6000000.0,
              5771415.0,
              5000000.0,
              4578350.0,
              3995595.0,
              4000000.0,
              3800000.0,
              3000000.0,
              2800000.0,
              2663730.0,
              2500000.0,
              2362700.0,
              3189645.0,
              2000000.0,
              1300000.0,
              1575450.0,
              1500000.0,
              1420656.0,
              1331865.0,
              1260360.0,
              1155330.0,
              1000000.0,
              1000000.0,
              1000000.0,
              976701.0,
              976701.0,
              945270.0,
              945270.0,
              1000000.0,
              10000000.0,
              5000000.0,
              5000000.0,
              2500000.0,
              20000000.0,
              4919490.0,
              17788000.0,
              7115200.0,
              7400000.0,
              5958980.0,
              2668200.0,
              2668200.0,
              3000000.0,
              1000000.0,
              2800000.0,
              2353560.0,
              1956680.0,
              10000000.0,
              2000000.0,
              1647492.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1570050.0,
              2000000.0,
              800000.0,
              1046700.0,
              2093400.0,
              30000000.0,
              2500000.0,
              1514162.0,
              980738.0,
              24964240.0,
              10000000.0,
              1800000.0,
              8000000.0,
              8915800.0,
              9050320.0,
              7279625.000000001,
              7400000.0,
              6700000.0,
              6241060.0,
              2400000.0,
              6241060.0,
              4696200.0,
              3000000.0,
              3000000.0,
              3000000.0,
              2500000.0,
              2329480.0,
              2000000.0,
              1747110.0,
              1600000.0,
              1397688.0,
              1281214.0,
              1240150.5,
              1200000.0,
              1069896.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              873834.5376,
              900000.0,
              850000.0,
              4457900.0,
              800000.0,
              2112705000.0,
              11448840.0,
              328000000.0,
              30294680.0,
              1611575.0,
              1100000.0,
              1229607.6,
              20047950.0,
              17820400.0,
              7000000.0,
              9344734.5225624,
              5000000.0,
              4158800.0,
              4000000.0,
              3100000.0,
              3000000.0,
              3000000.0,
              3000000.0,
              2851264.0,
              2599250.0,
              2000000.0,
              2000000.0,
              2000000.0,
              2000000.0,
              1854208.0,
              1000000.0,
              1158204.2,
              3933497.0,
              870388.4127999999,
              2157079.0,
              6173122.000000001,
              815318.0000000001,
              880680.0,
              6217330.0,
              838612.8,
              3000000.0,
              1470000.0,
              2500000.0,
              6289596.000000001,
              1065828.0,
              1200000.0,
              1782040.0,
              1400000.0,
              1400000.0,
              1274768.0,
              1200000.0,
              1069224.0,
              1143670.0,
              1050000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              891020.0,
              800000.0,
              2781312.0,
              6237140.0,
              2000000.0,
              1000000.0,
              1000000.0,
              2000000.0,
              1000000.0,
              5346120.0,
              11719790.4643523,
              1500000.0,
              7700000.0,
              62577900.0,
              54000000.0,
              11621610.0,
              10384000.0,
              5363820.0,
              5673250.0,
              6749600.0,
              1500000.0,
              5363820.0,
              5673250.0,
              3800000.0,
              3717472.0,
              3500000.0,
              3500000.0,
              2600000.0,
              2284480.0,
              2234925.0,
              2224200.0,
              2100000.0,
              2000000.0,
              2000000.0,
              3500000.0,
              1787940.0,
              3307536.0,
              1600000.0,
              1500000.0,
              1450000.0,
              1400000.0,
              1701975.0,
              1200000.0,
              1134650.0,
              1038400.0,
              1000000.0,
              1000000.0,
              800000.0,
              800000.0,
              778470.0,
              2700000.0,
              1000000.0,
              3000000.0,
              1250000.0,
              750000.0,
              15000000.0,
              8520579.9,
              3188850.0,
              1332600.0,
              22728417.75,
              30000000.0,
              19952280.0,
              15000000.0,
              12000000.0,
              9976140.0,
              9981950.0,
              9074500.0,
              9373000.0,
              7441090.0,
              7000000.0,
              6180000.0,
              5700000.0,
              5444700.0,
              5000000.0,
              3879610.0,
              3500000.0,
              2216920.0,
              2359370.0,
              3600000.0,
              2268625.0,
              2268625.0,
              2000000.0,
              2000000.0,
              1715080.5,
              1545000.0,
              1500000.0,
              1030000.0,
              1000000.0,
              907450.0,
              1000000.0,
              927000.0,
              907450.0,
              1000000.0,
              907450.0,
              800000.0,
              1000000.0,
              1500000.0,
              1600000.0,
              10000000.0,
              1250000.0,
              10334021.0,
              77133250.0,
              20000000.0,
              18149000.0,
              15880375.0,
              13301520.0,
              10000000.0,
              5874838.0,
              5320608.0,
              5000000.0,
              3990456.0,
              3325380.0,
              3000000.0,
              15000000.0,
              2994585.0,
              2163000.0,
              1250000.0,
              1133000.0,
              1108460.0,
              1088940.0,
              1088940.0,
              2722350.0,
              884763.75,
              850000.0,
              772500.0,
              9074500.0,
              1088940.0,
              22000000.0,
              3629800.0,
              3000000.0,
              3000000.0,
              2500000.0,
              2000000.0,
              1300000.0,
              1000000.0,
              1000000.0,
              22388800.0,
              18334200.0,
              13750650.0,
              13000000.0,
              12833940.0,
              12000000.0,
              10500000.0,
              10000000.0,
              750000.0,
              10000000.0,
              9167100.0,
              7333680.0,
              5000000.0,
              5000000.0,
              4583550.0,
              4500000.0,
              3358320.0,
              1268870.0,
              3000000.0,
              2798600.0,
              4619837.08592,
              2700000.0,
              2500000.0,
              2306825.0,
              2106885.71016,
              2000000.0,
              2104084.0,
              1910018.0,
              1833420.0,
              1800000.0,
              1679160.0,
              1500000.0,
              1400000.0,
              1331160.0,
              1250000.0,
              1231384.0,
              1119440.0,
              1100000.0,
              1021400.0,
              1021400.0,
              919260.0,
              1399318.0,
              1000000.0,
              2000000.0,
              1119440.0,
              926123.9064,
              1053747.90248,
              850000.0,
              27293700.0,
              4544150.0,
              15151500.0,
              32717880.0,
              20903090.0,
              20266909.0,
              18176600.0,
              10905960.0,
              4448000.0,
              3892000.0,
              3336000.0,
              3113600.0,
              3000000.0,
              2362958.0,
              2446400.0,
              2300000.0,
              2224000.0,
              2000000.0,
              2000000.0,
              1668000.0,
              1078800.0,
              1078800.0,
              884616.0,
              908830.0,
              769790.0,
              1000000.0,
              899530.0,
              4389450.0,
              39505050.0,
              46250485.2816,
              3236400.0,
              1343900.0,
              10000000.0,
              41239694.0,
              30000000.0,
              18008600.0,
              20272000.0,
              9250000.0,
              8000000.0,
              7000000.0,
              4502150.0,
              4400000.0,
              4400000.0,
              1000000.0,
              3469440.0,
              3151505.0,
              2891200.0,
              2500000.0,
              2000000.0,
              1737829.9,
              1620774.0,
              1734720.0,
              1317680.0,
              1272128.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1013600.0,
              2400000.0,
              750000.0,
              2500000.0,
              2150000.0,
              2832210.0,
              18881400.0,
              5000000.0,
              150000000.0,
              270948090.0,
              12467533.0,
              34955700.0,
              9440700.0,
              9157479.0,
              8119200.0,
              6900000.0,
              5243355.0,
              5000000.0,
              4720350.0,
              4720350.0,
              4200000.0,
              4200000.0,
              3700000.0,
              3600000.0,
              3500000.0,
              3000000.0,
              2832210.0,
              2400000.0,
              2330380.0,
              6089400.0,
              2097342.0,
              2000000.0,
              2000000.0,
              2330380.0,
              7850000.0,
              1600000.0,
              1510512.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              36818730.0,
              30000000.0,
              7300000.0,
              6800000.0,
              4704880.0,
              3000000.0,
              2793180.0,
              2822820.0,
              2200000.0,
              2009579.0,
              1500000.0,
              1533750.0,
              1529086.0,
              1411464.0,
              1227000.0,
              102975520.0,
              50000000.0,
              279779850.0,
              1000000.0,
              5000000.0,
              20456200.0,
              1000000.0,
              1000000.0,
              889400.0,
              1227990.0,
              880880.0,
              800000.0,
              20000000.0,
              15354000.0,
              100000000.0,
              42415200.0,
              18851200.0,
              10000000.0,
              9000000.0,
              7000000.0,
              7000000.0,
              16940210.0,
              3566360.0,
              5000000.0,
              5000000.0,
              3300000.0,
              3100000.0,
              2500000.0,
              2500000.0,
              2047200.0,
              2047200.0,
              1023600.0,
              1433040.0,
              1300000.0,
              1200000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              942560.0,
              942560.0,
              1000000.0,
              1000000.0,
              800000.0,
              181000000.0,
              1000000.0,
              2200000.0,
              1000000.0,
              23687800.0,
              22330080.0,
              17000000.0,
              10000000.0,
              7443360.0,
              4737560.0,
              3000000.0,
              3000000.0,
              3000000.0,
              3000000.0,
              948135.0,
              2600000.0,
              5157840.0,
              2368780.0,
              1750000.0,
              1500000.0,
              1200000.0,
              1200017.0,
              1000000.0,
              930420.0,
              800000.0,
              2186487.0,
              1000000.0,
              14000000.0,
              6303010.0,
              2500000.0,
              2300000.0,
              1500000.0,
              869800.0,
              7039998.0,
              58513406.0,
              33000000.0,
              30000000.0,
              9555104.0,
              10266000.0,
              8000000.0,
              6920676.0,
              6159600.0,
              5512560.0,
              5000000.0,
              5000000.0,
              4643400.0,
              4200000.0,
              3675040.0,
              1000000.0,
              3399412.0,
              3000000.0,
              2863728.0,
              2600000.0,
              2500000.0,
              2386440.0,
              2100000.0,
              2000000.0,
              1701975.0,
              1837520.0,
              1750000.0,
              1701975.0,
              1539900.0,
              1304847.5,
              1035000.0,
              1000000.0,
              1000000.0,
              750000.0,
              1000000.0,
              2000000.0,
              1000000.0,
              2000000.0,
              21000000.0,
              1000000.0,
              7000000.0,
              1248060.0,
              20000000.0,
              14300160.0,
              7000000.0,
              4603500.0,
              4921638.4,
              4000000.0,
              3785100.0,
              3828960.0,
              3500000.0,
              1808160.0,
              2105928.0,
              2000000.0,
              2000000.0,
              1700000.0,
              1310848.0,
              1100000.0,
              1023000.0,
              1000000.0,
              850000.0,
              750000.0,
              3000000.0,
              1000000.0,
              2000000.0,
              2000000.0,
              900000.0,
              22969000.0,
              43894500.0,
              30000000.0,
              8700000.0,
              4000000.0,
              1150000.0,
              800000.0,
              1852802.0,
              1747785.0,
              2060128.0,
              1903048.0,
              2190722.0,
              2411252.0,
              3000000.0,
              1500000.0,
              900000.0,
              5000000.0,
              7157150.0,
              75000000.0,
              119168000.0,
              7170075.0,
              4941900.0,
              4900000.0,
              4000000.0,
              2558806.0,
              1500000.0,
              1100000.0,
              2000000.0,
              9169800.0,
              8915900.0,
              8235150.0,
              7128160.0,
              2637750.0,
              2486505.0,
              2160000.0,
              2046000.0,
              2160000.0,
              2002030.0,
              1944000.0,
              1836000.0,
              17820400.0,
              1000000.0,
              2000000.0,
              1800000.0,
              1350000.0,
              1000000.0,
              1000000.0,
              9010558.0,
              90000000.0,
              18375200.0,
              48850450.0,
              14000000.0,
              9489800.0,
              9489800.0,
              8070599.999999999,
              5219390.0,
              370102200.0,
              3513690.0,
              3500000.0,
              2932160.0,
              2810952.0,
              2799510.0,
              2500000.0,
              2200000.0,
              2000000.0,
              1423470.0,
              902450.0,
              830000.0,
              1191680.0,
              37959200.0,
              92041600.0,
              40000000.0,
              15000000.0,
              16188640.0,
              10870860.0,
              6241130.0,
              10000000.0,
              10000000.0,
              9000000.0,
              8000000.0,
              7000000.0,
              6686925.0,
              5246500.0,
              5000000.0,
              5000000.0,
              5000000.0,
              3120565.0,
              3106404.0,
              832863.36,
              3227555.8,
              2924415.2,
              4178883.8,
              1833420.0,
              2700000.0,
              2761248.0,
              2761248.0,
              2500000.0,
              1961498.0,
              2000000.0,
              2000000.0,
              2000000.0,
              1400000.0,
              1270000.0,
              1214730.0,
              1150520.0,
              1000000.0,
              1000000.0,
              944370.0,
              49037450.0,
              1000000.0,
              687532.5,
              2674770.0,
              1600000.0,
              2700000.0,
              750000.0,
              47060000.0,
              164710000.0,
              14000000.0,
              12000000.0,
              5000000.0,
              12000000.0,
              2410853.0,
              1007932.8,
              798246.7799999999,
              1808879.4,
              10353200.0,
              1000000.0,
              1237502.0,
              2230000.0,
              14358600.0,
              2000000.0,
              8628900.0,
              8394400.0,
              7623720.0,
              6700000.0,
              2123858.4,
              5500000.0,
              5839340.0,
              4700000.0,
              14700160.0,
              3000000.0,
              3147900.0,
              3000000.0,
              2098600.0,
              2000000.0,
              1955884.0,
              1800000.0,
              1600000.0,
              1600040.0,
              1500000.0,
              1400000.0,
              1150520.0,
              1100000.0,
              1117812.0,
              918760.0,
              1200000.0,
              1049300.0,
              1000000.0,
              1000000.0,
              1049300.0,
              6000000.0,
              1600000.0,
              3000000.0,
              931510.0,
              791783.5,
              2000000.0,
              1752855.0,
              1752855.0,
              1191941.4,
              10300000.0,
              2000000.0,
              785790.362,
              3299608.0,
              9646980.0,
              2447120.0,
              1430000.0,
              1102512.0,
              1300000.0,
              3000000.0,
              1905645.0,
              1837520.0,
              1514747.0,
              1300000.0,
              2377000.0,
              15000000.0,
              4615851.5,
              1069896.0,
              155298000.0,
              58000000.0,
              32942000.0,
              30000000.0,
              28236000.0,
              26000000.0,
              13176800.0,
              11000000.0,
              11294400.0,
              6588400.0,
              6465195.0,
              6465195.0,
              5000000.0,
              4000000.0,
              4000000.0,
              3764800.0,
              3644019.0,
              3000000.0,
              3000000.0,
              2400000.0,
              2000000.0,
              2000000.0,
              2000000.0,
              2000000.0,
              1763235.0,
              1500000.0,
              1747980.0,
              1250000.0,
              1200000.0,
              1200000.0,
              1175490.0,
              1057700.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              940000.0,
              935720.0,
              900000.0,
              900000.0,
              1000000.0,
              1000000.0,
              1500000.0,
              1000000.0,
              1662690.0,
              18500000.0,
              14000000.0,
              12000000.0,
              5000000.0,
              8422830.0,
              8000000.0,
              5289705.0,
              5200000.0,
              5147285.0,
              5000000.0,
              4700000.0,
              3900000.0,
              3500000.0,
              3067330.0,
              2700000.0,
              2600000.0,
              1500000.0,
              1500000.0,
              1500000.0,
              1403805.0,
              1200000.0,
              750000.0,
              1200000.0,
              1175490.0,
              1029457.0,
              1000000.0,
              1000000.0,
              1000000.0,
              951930.0,
              935870.0,
              750000.0,
              1000000.0,
              10000000.0,
              1000000.0,
              2000000.0,
              21592170.0,
              1976392.0,
              2900000.0,
              1620774.0,
              18000000.0,
              11265480.0,
              9000000.0,
              6571530.0,
              6571530.0,
              4444860.0,
              4200000.0,
              4000000.0,
              3300000.0,
              3000000.0,
              3000000.0,
              2000000.0,
              1754550.0,
              1637580.0,
              824902.0,
              1169700.0,
              1000000.0,
              1000000.0,
              800000.0,
              1000000.0,
              9488529.6,
              7399700.52855,
              46939500.0,
              25000000.0,
              23469750.0,
              20653380.0,
              1000000.0,
              1749908.8,
              4194300.0,
              3200000.0,
              9000000.0,
              15000000.0,
              14081850.0,
              14081850.0,
              9387900.0,
              8200000.0,
              6000000.0,
              6000000.0,
              6008256.0,
              5444982.0,
              32000000.0,
              5163345.0,
              5285500.0,
              3488430.0,
              3300000.0,
              3051067.5,
              3000000.0,
              2431330.0,
              2339400.0,
              2114200.0,
              2114200.0,
              2000000.0,
              2000000.0,
              2000000.0,
              1877580.0,
              1600000.0,
              1500000.0,
              1500000.0,
              1268520.0,
              1200000.0,
              1215665.0,
              1220427.0,
              1169700.0,
              1057100.0,
              1000000.0,
              845680.0,
              1000000.0,
              2625250.0,
              26043820.0,
              1878811.2,
              891320.5168,
              825564.6112,
              933216.5248,
              1103710.1824,
              15000000.0,
              14205720.0,
              11000000.0,
              2500000.0,
              10713480.5,
              7694765.0,
              7043325.0,
              3286885.0,
              6141460.0,
              5919050.0,
              6141460.0,
              5500000.0,
              1000000.0,
              6737700.0,
              3500000.0,
              3543150.0,
              3000000.0,
              3150300.0,
              2367620.0,
              6613880.0,
              1889680.0,
              1700000.0,
              1408665.0,
              1228292.0,
              1150000.0,
              1183810.0,
              1100000.0,
              1050100.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              944840.0,
              891020.0,
              840080.0,
              792820.0,
              2000000.0,
              1000000.0,
              18896800.0,
              1000000.0,
              2411550.0,
              11663200.0,
              9541350.0,
              9447192.0,
              3744678.0,
              4457950.0,
              5000000.0,
              5000000.0,
              5000000.0,
              5131808.0,
              4500000.0,
              4500000.0,
              4101314.0,
              4198752.0,
              3744678.0,
              3500000.0,
              3498960.0,
              3233666.0,
              3145500.0,
              3000000.0,
              2700000.0,
              2500000.0,
              2000000.0,
              2500000.0,
              2516400.0,
              1415960.0,
              2332640.0,
              2332640.0,
              1398228.0,
              2100000.0,
              1500000.0,
              2400000.0,
              2400000.0,
              2000000.0,
              2000000.0,
              1878840.0,
              1160000.0,
              1572750.0,
              1500000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              802431.0,
              750000.0,
              2000000.0,
              1000000.0,
              95071520.0,
              43500000.0,
              33000000.0,
              31205650.0,
              22908800.0,
              13745280.0,
              10699080.0,
              10000000.0,
              10000000.0,
              8915900.0,
              7000000.0,
              4300000.0,
              6000000.0,
              5400000.0,
              4832300.0,
              4457950.0,
              4622200.0,
              4581760.0,
              1935329.6,
              4000000.0,
              2806328.0,
              2200000.0,
              2000000.0,
              1400000.0,
              1200000.0,
              1200000.0,
              876261.6,
              800000.0,
              800000.0,
              750000.0,
              1000000.0,
              750000.0,
              4500000.0,
              26300000.0,
              12200000.0,
              22999520.0,
              20000000.0,
              16693200.0,
              11128800.0,
              10000000.0,
              8624820.0,
              8247168.0,
              5000000.0,
              950000.0,
              8624820.0,
              1052000.0,
              3500000.0,
              3500000.0,
              3321776.0,
              3000000.0,
              2634512.0,
              4581760.0,
              1378140.0,
              2259432.0,
              1500000.0,
              1500000.0,
              1145440.0,
              1259984.0,
              1158960.0,
              1112880.0,
              1075920.0,
              1053600.0,
              1053600.0,
              1053600.0,
              871360.0,
              1053600.0,
              750000.0,
              750000.0,
              2000000.0,
              3000000.0,
              2000000.0,
              950000.0,
              19200000.0,
              1000000.0,
              1600000.0,
              1770000.0,
              64918000.0,
              14838400.0,
              12056200.0,
              9274000.0,
              9212000.0,
              7419200.0,
              5000000.0,
              5564400.0,
              4637000.0,
              3454500.0,
              1000000.0,
              3160800.0,
              3060420.0,
              3000000.0,
              2878750.0,
              2503980.0,
              2000000.0,
              2000000.0,
              973214.0,
              1854800.0,
              1854800.0,
              1580400.0,
              1500000.0,
              1000000.0,
              1317000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              942560.0,
              1000000.0,
              325818500.0,
              1000000.0,
              750000.0,
              5138630.0,
              65000000.0,
              50000000.0,
              21000000.0,
              988196.0,
              15000000.0,
              12500000.0,
              1000000.0,
              9574968.0,
              6444690.0,
              6100000.0,
              5243500.0,
              5243500.0,
              5000000.0,
              4603350.0,
              4300000.0,
              4318125.0,
              1466609.0,
              4000000.0,
              1100000.0,
              3860000.0,
              3146100.0,
              3000000.0,
              2832690.0,
              2500000.0,
              2500000.0,
              2400000.0,
              2097400.0,
              2000000.0,
              2000000.0,
              2000000.0,
              1500000.0,
              1162161.0,
              1600000.0,
              1300000.0,
              2000000.0,
              1900000.0,
              1000000.0,
              3500000.0,
              1151500.0,
              893970.0,
              1841340.0,
              1800000.0,
              1800000.0,
              1600000.0,
              1500000.0,
              1496950.0,
              1450000.0,
              1300000.0,
              1250000.0,
              1100000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              943830.0,
              943830.0,
              920670.0,
              921200.0,
              790000.0,
              806050.0,
              2689800.0,
              2000000.0,
              9943650.0,
              9313600.0,
              8382240.0,
              7500000.0,
              5774432.0,
              5500000.0,
              1238636.0,
              5588160.0,
              5122480.0,
              8382240.0,
              4000000.0,
              4032300.0,
              2922225.0,
              1168890.0,
              2514672.0,
              2337780.0,
              17382330.0,
              18112835.0,
              3200000.0,
              2200000.0,
              2000000.0,
              2000000.0,
              7700000.0,
              1862720.0,
              1753335.0,
              1753335.0,
              1500000.0,
              1285779.0,
              1200000.0,
              1168890.0,
              1168890.0,
              1048700.0,
              1000000.0,
              950000.0,
              1000000.0,
              2000000.0,
              1000000.0,
              838960.0,
              1000000.0,
              2550000.0,
              1500000.0,
              755064.0,
              2178400.0,
              2505600.0,
              6264000.0,
              70448250.0,
              30000000.0,
              750000.0,
              19196347.0,
              17665350.0,
              8832675.0,
              6000000.0,
              1408965.0,
              4696550.0,
              4602619.0,
              3495440.0,
              3275405.0,
              3475447.0,
              3099723.0,
              2000000.0,
              1882400.0,
              1411800.0,
              2088000.0,
              2917720.0,
              2708687.0,
              2532033.5,
              1746326.4,
              873892.5,
              1165190.0,
              2400000.0,
              2088000.0,
              2088000.0,
              1882400.0,
              1566000.0,
              1000000.0,
              941200.0,
              1000000.0,
              2000000.0,
              751680.0,
              3000000.0,
              14081850.0,
              750000.0,
              1000000.0,
              4200000.0,
              5500000.0,
              3000000.0,
              10401000.0,
              2798130.0,
              19640460.0,
              15669528.0,
              4000000.0,
              6199000.0,
              15000000.0,
              8000000.0,
              15000000.0,
              34600000.0,
              15000000.0,
              3800000.0,
              1500000.0,
              3000000.0,
              2704859.0,
              2392230.0,
              2145233.0,
              1700000.0,
              3500000.0,
              1674260.0,
              1500000.0,
              1300000.0,
              1300000.0,
              1200000.0,
              1040100.0,
              1350000.0,
              1000000.0,
              1000000.0,
              800000.0,
              2500000.0,
              5000000.0,
              1000000.0,
              750000.0,
              3807240.0,
              3587700.0,
              7000000.0,
              64267700.0,
              55086600.0,
              2762010.0,
              2244175.0,
              30571580.0,
              2103426.0,
              27543300.0,
              910861.0,
              40471600.0,
              1545096.0,
              3753792.0,
              4115405.0,
              1197136.8,
              1421531.8,
              771856.8117,
              1298337.8366,
              2021272.0,
              754961.5,
              964698.0,
              828667.0,
              16066925.0,
              16461620.0,
              14414327.0,
              13500000.0,
              8000000.0,
              12000000.0,
              10405000.0,
              10099210.0,
              10000000.0,
              10112138.0,
              2500000.0,
              9181100.0,
              2282470.4,
              8513009.2,
              7800000.0,
              6000000.0,
              5300000.0,
              4000000.0,
              4000000.0,
              3409907.0,
              3121500.0,
              3000000.0,
              2000000.0,
              2000000.0,
              2000000.0,
              2000000.0,
              1836220.0,
              1469787.5,
              1400000.0,
              1144550.0,
              1175830.0,
              1000000.0,
              2000000.0,
              918110.0,
              918110.0,
              918110.0,
              1200000.0,
              800000.0,
              750000.0,
              6000000.0,
              9406640.0,
              1800000.0,
              750000.0,
              1500000.0,
              82600000.0,
              9406640.0,
              1336530.0,
              16514000.0,
              32800000.0,
              110173200.0,
              21047357.0,
              15607870.0,
              16000000.0,
              14109960.0,
              1884540.0,
              22118500.0,
              13000000.0,
              3000000.0,
              13404462.0,
              14392000.0,
              10405000.0,
              8262990.0,
              7000000.0,
              750000.0,
              54088180.0,
              5508660.0,
              3121500.0,
              1500000.0,
              3121500.0,
              3000000.0,
              2351660.0,
              2537740.0,
              2578920.0,
              2351660.0,
              1664800.0,
              1600000.0,
              1500000.0,
              1300000.0,
              1000000.0,
              1000000.0,
              34400000.0,
              1000000.0,
              2000000.0,
              1750000.0,
              1500000.0,
              800000.0,
              461071940.0,
              110000000.0,
              91847000.0,
              750000.0,
              41331150.0,
              36738800.0,
              25000000.0,
              10000000.0,
              9184700.0,
              9184700.0,
              9184700.0,
              16471000.0,
              8000000.0,
              15000000.0,
              8000000.0,
              750000.0,
              6000000.0,
              5000000.0,
              4704350.0,
              4000000.0,
              4000000.0,
              3764800.0,
              3311924.0,
              2447120.0,
              7529600.0,
              2500000.0,
              800000.0,
              2282575.0,
              1826060.0,
              2300000.0,
              2000000.0,
              2000000.0,
              8292780.0,
              1500000.0,
              1230000.0,
              1200000.0,
              1000000.0,
              1034976.25,
              931859.9999999999,
              800000.0,
              750000.0,
              1000000.0,
              941200.0,
              10000000.0,
              1000000.0,
              1000000.0,
              3200000.0,
              387000000.0,
              25647820.0,
              22336280.0,
              18410025.0,
              17487150.0,
              20618560.0,
              15266850.0,
              15000000.0,
              13319800.0,
              3176260.0,
              10776600.0,
              8000000.0,
              8000000.0,
              4700000.0,
              2900000.0,
              1200000.0,
              2450000.0,
              1800000.0,
              3847173.0,
              1200000.0,
              1100000.0,
              1024600.0,
              1302172.5,
              973370.0,
              1000000.0,
              768450.0,
              1000000.0,
              52461450.0,
              11270600.0,
              3730000.0,
              5000000.0,
              1000000.0,
              2000000.0,
              360000000.0,
              22904000.0,
              22000000.0,
              196790000.0,
              16000000.0,
              15000000.0,
              15000000.0,
              6500000.0,
              5725000.0,
              5725000.0,
              5123000.0,
              3600000.0,
              3435000.0,
              2519000.0,
              2356580.0,
              2236250.0,
              2290000.0,
              2000000.0,
              1600000.0,
              1100000.0,
              1045092.0,
              1000000.0,
              805050.0,
              66000000.0,
              1000000.0,
              5123000.0,
              20000000.0,
              3073800.0,
              2561500.0,
              2000000.0,
              983950.0,
              2000000.0,
              3000000.0,
              45000000.0,
              36544320.0,
              20548000.0,
              15000000.0,
              14846130.0,
              10522000.0,
              8000000.0,
              7821530.0,
              6000000.0,
              5500000.0,
              5521080.0,
              4696277.0,
              1096548.0,
              4100000.0,
              4000000.0,
              3500000.0,
              5200000.0,
              2879792.5,
              2688200.0,
              2500000.0,
              2500000.0,
              2250000.0,
              2000000.0,
              1772180.0,
              1400000.0,
              1200000.0,
              1370412.0,
              1142010.0,
              288508350.0,
              20000000.0,
              15188820.0,
              6879642.0,
              2200000.0,
              1707150.0,
              1700000.0,
              1072152.0,
              1194388.0,
              1000000.0,
              1024290.0,
              1008075.0,
              1000000.0,
              1377165.0,
              2228950.0,
              2219643.75,
              1445600.0,
              47154630.0,
              25000000.0,
              7562535.0,
              7700000.0,
              7000000.0,
              7000000.0,
              6000000.0,
              5560000.000000001,
              5400000.0,
              4359579.0,
              3668000.0,
              3558840.0,
              3000000.0,
              3000000.0,
              3000000.0,
              2758101.0,
              2500000.0,
              2400000.0,
              2054800.0,
              2000000.0,
              1750000.0,
              1541100.0,
              1500000.0,
              1400000.0,
              1334565.0,
              1334565.0,
              1000000.0,
              800000.0,
              1050000.0,
              1000000.0,
              1000000.0,
              921390.0,
              750000.0,
              51000000.0,
              51283800.0,
              26824500.0,
              25000000.0,
              20700000.0,
              17154750.0,
              11639550.0,
              2000000.0,
              10000000.0,
              10000000.0,
              10000000.0,
              5372100.0,
              3741725.6,
              2800000.0,
              2506980.0,
              2281540.0,
              2200000.0,
              1500000.0,
              2000000.0,
              2000000.0,
              2000000.0,
              1711155.0,
              1478950.0,
              1343025.0,
              1368924.0,
              1300000.0,
              1100000.0,
              1024500.0,
              1000000.0,
              1000000.0,
              1000000.0,
              3000000.0,
              1000000.0,
              1000000.0,
              884096.75,
              819600.0,
              800000.0,
              819600.0,
              1078300.0,
              10000000.0,
              13409911.76976,
              355648480.0,
              35833600.0,
              34047600.0,
              30000000.0,
              25000000.0,
              23291840.0,
              9854240.0,
              10000000.0,
              5000000.0,
              5000000.0,
              4539680.0,
              4539680.0,
              6400000.0,
              3583360.0,
              3407130.0,
              2687520.0,
              2687520.0,
              2300000.0,
              2303050.0,
              2239600.0,
              2275600.0,
              2049200.0,
              2000000.0,
              1700000.0,
              1100000.0,
              1024600.0,
              1024600.0,
              1024600.0,
              967129.9999999999,
              891402.0,
              690247.5,
              30637600.0,
              19257920.0,
              20000000.0,
              13657800.0,
              10000000.0,
              8753600.0,
              8315920.0,
              8000000.0,
              8000000.0,
              7200000.0,
              6915344.0,
              7000000.0,
              6000000.0,
              5000000.0,
              4563650.0,
              4189600.0,
              4200000.0,
              3000000.0,
              3000000.0,
              2848675.0,
              2164993.0,
              2000000.0,
              2073400.0,
              1800000.0,
              7090416.0,
              1709205.0,
              1500000.0,
              1500000.0,
              1400576.0,
              2000000.0,
              1200000.0,
              2000000.0,
              1000000.0,
              1000000.0,
              1000000.0,
              2000000.0,
              7440560.0,
              1000000.0,
              1000000.0,
              1000000.0,
              1500000.0,
              1000000.0,
              1400000.0,
              889400.0,
              1000000.0,
              1000000.0,
              862890.0,
              1312542.0,
              2000000.0,
              10000000.0,
              4389850.0,
              12900000.0,
              5000000.0,
              4000000.0,
              2500000.0,
              400000000.0,
            ],
            type: 'box',
            marker: { color: '#3CBEC5' },
            name: 'All Deals',
          },
        ]}
        layout={{
          width: '100%',
          height: '100%',
          title: 'Startup Investments 2016-2017',
          xaxis: { title: 'Amount raised', range: [0, 100000000] },
        }}
      />
    </div>
  )
}

export default StartUpBarChart
