import React from 'react'
import Plot from '$components/PlotlyWrapper';

export default () => {
  return (
    <div
      className="container my-5 d-flex justify-content-center"
      style={{ height: '400px' }}
    >
      <Plot
        data={[
          {
            type: 'scatter',
            x: [
              '2017-01-01',
              '2016-10-01',
              '2017-03-01',
              '2016-06-01',
              '2016-03-01',
              '2017-04-01',
              '2016-01-01',
              '2016-07-01',
              '2016-11-01',
            ],
            y: [2.0, 1.0, 1.0, 1.0, 1.0, 2.0, 1.0, 2.0, 2.0],
            mode: 'markers',
            name: 'Autonomous Vehicles',
            opacity: 0.6,
            marker: {
              color: 'hsl(0.0,60%,60%)',
              size: [70, 70, 70, 70, 60.0, 59.97269, 41.0, 23.491815, 14.47995],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 192.1M',
              'Median: 30.0M',
              'Median: 16.7M',
              'Median: 8.0M',
              'Median: 6.0M',
              'Median: 6.0M',
              'Median: 4.1M',
              'Median: 2.3M',
              'Median: 1.4M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-07-01',
              '2016-02-01',
              '2016-11-01',
              '2017-05-01',
              '2017-06-01',
              '2016-12-01',
              '2016-03-01',
              '2017-04-01',
              '2016-06-01',
              '2017-02-01',
              '2016-10-01',
              '2016-04-01',
              '2016-09-01',
              '2016-08-01',
              '2017-01-01',
              '2017-03-01',
              '2016-05-01',
            ],
            y: [
              2.0,
              2.0,
              3.0,
              4.0,
              4.0,
              3.0,
              3.0,
              2.0,
              2.0,
              2.0,
              6.0,
              5.0,
              6.0,
              2.0,
              2.0,
              4.0,
              1.0,
            ],
            mode: 'markers',
            name: 'Virtual Reality',
            opacity: 0.6,
            marker: {
              color: 'hsl(30.0,60%,60%)',
              size: [
                70,
                70,
                70,
                70,
                52.4755,
                46.434,
                44.918,
                42.046165,
                26.0085,
                22.71575,
                21.343125,
                17.0,
                15.9102,
                15.438225,
                14.243,
                13.5,
                10,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 92.0M',
              'Median: 45.0M',
              'Median: 18.2M',
              'Median: 11.1M',
              'Median: 5.2M',
              'Median: 4.6M',
              'Median: 4.5M',
              'Median: 4.2M',
              'Median: 2.6M',
              'Median: 2.3M',
              'Median: 2.1M',
              'Median: 1.7M',
              'Median: 1.6M',
              'Median: 1.5M',
              'Median: 1.4M',
              'Median: 1.4M',
              'Median: 0.8M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-05-01',
              '2016-06-01',
              '2016-11-01',
              '2017-04-01',
              '2016-08-01',
              '2016-05-01',
              '2016-09-01',
              '2016-04-01',
              '2016-03-01',
              '2016-10-01',
              '2017-03-01',
            ],
            y: [1.0, 1.0, 1.0, 1.0, 1.0, 2.0, 6.0, 1.0, 1.0, 2.0, 1.0],
            mode: 'markers',
            name: '3D printing',
            opacity: 0.6,
            marker: {
              color: 'hsl(60.0,60%,60%)',
              size: [
                70,
                70,
                70,
                70.0,
                62.8369,
                52.0795,
                32.8318,
                32.09724,
                30.0,
                16.343125,
                14.66609,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 66.0M',
              'Median: 16.9M',
              'Median: 15.2M',
              'Median: 7.0M',
              'Median: 6.3M',
              'Median: 5.2M',
              'Median: 3.3M',
              'Median: 3.2M',
              'Median: 3.0M',
              'Median: 1.6M',
              'Median: 1.5M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-04-01',
              '2016-01-01',
              '2017-06-01',
              '2016-10-01',
              '2017-03-01',
              '2016-06-01',
            ],
            y: [1.0, 1.0, 1.0, 1.0, 1.0, 1.0],
            mode: 'markers',
            name: 'Chatbot',
            opacity: 0.6,
            marker: {
              color: 'hsl(90.0,60%,60%)',
              size: [70, 70, 25.0, 14.0, 13.0, 10.0],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 55.1M',
              'Median: 23.0M',
              'Median: 2.5M',
              'Median: 1.4M',
              'Median: 1.3M',
              'Median: 1.0M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-06-01',
              '2016-06-01',
              '2016-11-01',
              '2017-01-01',
              '2017-04-01',
              '2016-03-01',
              '2016-12-01',
              '2017-03-01',
              '2017-02-01',
              '2016-01-01',
              '2016-10-01',
              '2016-09-01',
              '2016-04-01',
              '2016-08-01',
              '2016-05-01',
            ],
            y: [
              1.0,
              2.0,
              4.0,
              3.0,
              4.0,
              3.0,
              4.0,
              9.0,
              4.0,
              4.0,
              1.0,
              1.0,
              1.0,
              1.0,
              3.0,
            ],
            mode: 'markers',
            name: 'API',
            opacity: 0.6,
            marker: {
              color: 'hsl(120.0,60%,60%)',
              size: [
                70,
                70,
                64.447,
                52.465,
                40.25,
                23.0,
                20.0,
                19.0,
                16.102135,
                14.85008,
                12.5,
                10.0,
                10.0,
                10.0,
                10,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 47.2M',
              'Median: 17.5M',
              'Median: 6.4M',
              'Median: 5.2M',
              'Median: 4.0M',
              'Median: 2.3M',
              'Median: 2.0M',
              'Median: 1.9M',
              'Median: 1.6M',
              'Median: 1.5M',
              'Median: 1.2M',
              'Median: 1.0M',
              'Median: 1.0M',
              'Median: 1.0M',
              'Median: 0.9M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-11-01',
              '2017-01-01',
              '2017-05-01',
              '2016-07-01',
              '2017-06-01',
              '2016-10-01',
              '2016-03-01',
              '2016-06-01',
              '2016-09-01',
              '2017-04-01',
              '2016-01-01',
              '2017-03-01',
              '2016-08-01',
              '2017-02-01',
              '2016-04-01',
            ],
            y: [
              1.0,
              2.0,
              3.0,
              1.0,
              3.0,
              2.0,
              2.0,
              1.0,
              3.0,
              1.0,
              1.0,
              6.0,
              2.0,
              2.0,
              1.0,
            ],
            mode: 'markers',
            name: 'Blockchain',
            opacity: 0.6,
            marker: {
              color: 'hsl(150.0,60%,60%)',
              size: [
                70,
                70,
                70,
                70,
                70.0,
                48.46525,
                47.459,
                35.0,
                30.0,
                27.9813,
                27.5628,
                24.03164,
                21.679307199999997,
                10,
                10,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 36.8M',
              'Median: 15.1M',
              'Median: 14.4M',
              'Median: 9.3M',
              'Median: 7.0M',
              'Median: 4.8M',
              'Median: 4.7M',
              'Median: 3.5M',
              'Median: 3.0M',
              'Median: 2.8M',
              'Median: 2.8M',
              'Median: 2.4M',
              'Median: 2.2M',
              'Median: 1.0M',
              'Median: 0.8M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-01-01',
              '2016-03-01',
              '2016-08-01',
              '2016-10-01',
              '2016-05-01',
              '2017-04-01',
              '2016-11-01',
              '2016-06-01',
              '2017-01-01',
              '2017-06-01',
              '2017-03-01',
            ],
            y: [3.0, 1.0, 1.0, 1.0, 1.0, 1.0, 2.0, 1.0, 1.0, 1.0, 3.0],
            mode: 'markers',
            name: 'Smart cities',
            opacity: 0.6,
            marker: {
              color: 'hsl(180.0,60%,60%)',
              size: [70, 70, 70, 50.0, 38.0724, 35.877, 27.5, 10, 10, 10, 10],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 16.5M',
              'Median: 10.0M',
              'Median: 10.0M',
              'Median: 5.0M',
              'Median: 3.8M',
              'Median: 3.6M',
              'Median: 2.8M',
              'Median: 0.9M',
              'Median: 0.9M',
              'Median: 0.8M',
              'Median: 0.8M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-03-01',
              '2016-05-01',
              '2017-02-01',
              '2016-11-01',
              '2016-12-01',
              '2017-04-01',
              '2016-07-01',
              '2016-04-01',
              '2017-06-01',
              '2016-06-01',
              '2017-03-01',
            ],
            y: [1.0, 1.0, 2.0, 1.0, 3.0, 1.0, 2.0, 2.0, 2.0, 1.0, 1.0],
            mode: 'markers',
            name: 'Drones',
            opacity: 0.6,
            marker: {
              color: 'hsl(210.0,60%,60%)',
              size: [
                70,
                70,
                70,
                50.0,
                35.0,
                30.0,
                24.86686,
                13.5,
                12.25,
                10.0,
                10,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 13.0M',
              'Median: 8.8M',
              'Median: 8.0M',
              'Median: 5.0M',
              'Median: 3.5M',
              'Median: 3.0M',
              'Median: 2.5M',
              'Median: 1.4M',
              'Median: 1.2M',
              'Median: 1.0M',
              'Median: 0.8M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2017-02-01',
              '2016-04-01',
              '2016-07-01',
              '2017-05-01',
              '2017-06-01',
              '2016-03-01',
              '2016-02-01',
              '2016-12-01',
              '2016-05-01',
              '2017-04-01',
              '2016-11-01',
              '2016-08-01',
              '2016-10-01',
              '2017-01-01',
              '2017-03-01',
              '2016-06-01',
              '2016-09-01',
              '2016-01-01',
            ],
            y: [
              4.0,
              12.0,
              19.0,
              9.0,
              7.0,
              10.0,
              13.0,
              15.0,
              14.0,
              10.0,
              13.0,
              13.0,
              9.0,
              17.0,
              15.0,
              18.0,
              15.0,
              9.0,
            ],
            mode: 'markers',
            name: 'Connected Hardware',
            opacity: 0.6,
            marker: {
              color: 'hsl(240.0,60%,60%)',
              size: [
                70,
                70,
                64.0,
                60.0,
                60.0,
                51.33605,
                45.0653,
                35.0,
                34.8166,
                31.020635,
                31.0,
                30.0,
                27.2235,
                25.0,
                24.0,
                23.45361,
                22.2895,
                18.3752,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 12.9M',
              'Median: 12.2M',
              'Median: 6.4M',
              'Median: 6.0M',
              'Median: 6.0M',
              'Median: 5.1M',
              'Median: 4.5M',
              'Median: 3.5M',
              'Median: 3.5M',
              'Median: 3.1M',
              'Median: 3.1M',
              'Median: 3.0M',
              'Median: 2.7M',
              'Median: 2.5M',
              'Median: 2.4M',
              'Median: 2.3M',
              'Median: 2.2M',
              'Median: 1.8M',
            ],
          },
          {
            type: 'scatter',
            x: [
              '2016-07-01',
              '2016-09-01',
              '2016-03-01',
              '2016-02-01',
              '2017-05-01',
              '2016-05-01',
              '2017-02-01',
              '2016-12-01',
              '2016-11-01',
              '2017-01-01',
              '2017-03-01',
              '2016-10-01',
              '2016-06-01',
              '2017-04-01',
              '2017-06-01',
            ],
            y: [
              3.0,
              1.0,
              1.0,
              1.0,
              3.0,
              1.0,
              2.0,
              2.0,
              1.0,
              1.0,
              2.0,
              4.0,
              1.0,
              1.0,
              1.0,
            ],
            mode: 'markers',
            name: 'Robotics',
            opacity: 0.6,
            marker: {
              color: 'hsl(270.0,60%,60%)',
              size: [
                70,
                70,
                70,
                50.0,
                36.0,
                36.0,
                31.3426,
                29.0,
                27.9318,
                20.0,
                19.55775,
                16.5724,
                10.0,
                10.0,
                10,
              ],
              line: { width: 1, color: '#adadad' },
            },
            text: [
              'Median: 10.0M',
              'Median: 8.9M',
              'Median: 8.0M',
              'Median: 5.0M',
              'Median: 3.6M',
              'Median: 3.6M',
              'Median: 3.1M',
              'Median: 2.9M',
              'Median: 2.8M',
              'Median: 2.0M',
              'Median: 2.0M',
              'Median: 1.7M',
              'Median: 1.0M',
              'Median: 1.0M',
              'Median: 0.7M',
            ],
          },
        ]}
        layout={{
          width: '100%',
          height: '100%',
          title: '# Investments per Technology (Size is Median of investment)',
          xaxis: { title: 'Date' },
          yaxis: { title: '# Investments' },
        }}
      />
    </div>
  )
}
